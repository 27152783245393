import React from 'react'
import { Button } from 'reactstrap'
import { WithAuth } from 'authentication/components'
import Branding from 'shared/branding'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ONLINE_STORE_BRAND_NAME } from 'constants/values'
import authService from 'authentication/service'
import PropTypes from 'prop-types'

const LoginLogoutButton = ({ className, setModalOpen, showLogInText }) => {
  const branding = Branding.current
  const { redirectToP360 } = useFlags()
  const isP360Domain = branding.key === ONLINE_STORE_BRAND_NAME.P360

  const handleLogIn = () =>
    authService.loginRedirect(redirectToP360, isP360Domain)

  const signOut = () => {
    authService.signOut(false, true)
    authService.loginRedirect(redirectToP360, isP360Domain)
  }

  const handleLogOut = () => {
    const isPasswordless = localStorage.getItem('isPasswordlessFlow') === 'true'
    if (isPasswordless && setModalOpen) {
      setModalOpen(true)
      return
    }

    signOut()
  }

  return (
    <WithAuth>
      {({ isAuthenticated }) => {
        const [buttonId, handler, description] = isAuthenticated && !showLogInText
          ? ['logout', handleLogOut, 'Log Out']
          : ['login', handleLogIn, 'Log In']

        return (
          <Button
            id={`${buttonId}_button--button-${buttonId}`}
            data-test={`buttons.${buttonId}`}
            className={className}
            color={branding.headerColor}
            onClick={handler}
          >
            <i className="fas fa-lock" /> {description}
          </Button>
        )
      }}
    </WithAuth>
  )
}

LoginLogoutButton.propTypes = {
  className: PropTypes.string,
  setModalOpen: PropTypes.func,
  showLogInText: PropTypes.bool
}

export default LoginLogoutButton
