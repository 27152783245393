export const AK = {
  value: 'AK',
  name: 'Alaska',
  idPattern: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numbers'],
  },
}

export const AL = {
  value: 'AL',
  name: 'Alabama',
  idPattern: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numbers'],
  },
}

export const AZ = {
  value: 'AZ',
  name: 'Arizona',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{1,8}$)|(^[A-Za-z]{2}[0-9]{2,5}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 1-8 Numeric',
      '2 Alpha + 2-5 Numeric',
      '9 Numeric',
    ],
  },
}

export const AR = {
  value: 'AR',
  name: 'Arkansas',
  idPattern: {
    rule: '^[0-9]{4,9}$',
    description: ['4-9 Numeric'],
  },
}

export const CA = {
  value: 'CA',
  name: 'California',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{7}$',
    description: ['1 Alpha + 7 Numeric'],
  },
}

export const CO = {
  value: 'CO',
  name: 'Colorado',
  idPattern: {
    rule: '(^[0-9]{9}$)|(^[A-Za-z]{1}[0-9]{3,6}$)|(^[A-Za-z]{2}[0-9]{2,5}$)',
    description: [
      '9 Numeric',
      '1 Alpha + 3-6 Numeric',
      '2 Alpha + 2-5 Numeric',
    ],
  },
}

export const CT = {
  value: 'CT',
  name: 'Connecticut',
  idPattern: {
    rule: '^[0-9]{9}$',
    description: ['9 Numeric'],
  },
}

export const DC = {
  value: 'DC',
  name: 'District of Columbia',
  idPattern: {
    rule: '(^[0-9]{7}$)|(^[0-9]{9}$)',
    description: ['7 Numeric', '9 Numeric'],
  },
}

export const DE = {
  value: 'DE',
  name: 'Delaware',
  idPattern: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numeric'],
  },
}

export const FL = {
  value: 'FL',
  name: 'Florida',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric'],
  },
}

export const GA = {
  value: 'GA',
  name: 'Georgia',
  idPattern: {
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric'],
  },
}

export const HI = {
  value: 'HI',
  name: 'Hawaii',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '9 Numeric'],
  },
}

export const IA = {
  value: 'IA',
  name: 'Iowa',
  idPattern: {
    rule: '^([0-9]{9}|([0-9]{3}[A-Za-z]{2}[0-9]{4}))$',
    description: ['9 Numeric', '3 Numeric + 2 Alpha + 4 Numeric'],
  },
}

export const ID = {
  value: 'ID',
  name: 'Idaho',
  idPattern: {
    rule: '(^[A-Za-z]{2}[0-9]{6}[A-Za-z]{1}$)|(^[0-9]{9}$)',
    description: ['2 Alpha + 6 Numeric + 1 Alpha', '9 Numeric'],
  },
}

export const IL = {
  value: 'IL',
  name: 'Illinois',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{11,12}$',
    description: ['1 Alpha + 11-12 Numeric'],
  },
}

export const IN = {
  value: 'IN',
  name: 'Indiana',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{9}$)|(^[0-9]{9,10}$)',
    description: ['1 Alpha + 9 Numeric', '9-10 Numeric'],
  },
}

export const KS = {
  value: 'KS',
  name: 'Kansas',
  idPattern: {
    rule:
      '(^([A-Za-z]{1}[0-9]{1}){2}[A-Za-z]{1}$)|(^[A-Za-z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 1 Numeric + 1 Alpha + 1 Numeric + 1 Alpha',
      '1 Alpha + 8 Numeric',
      '9 Numeric',
    ],
  },
}

export const KY = {
  value: 'KY',
  name: 'Kentucky',
  idPattern: {
    rule: '(^[A_Z]{1}[0-9]{8,9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8-9 Numeric', '9 Numeric'],
  },
}

export const LA = {
  value: 'LA',
  name: 'Louisiana',
  idPattern: {
    rule: '^[0-9]{1,9}$',
    description: ['1-9 Numeric'],
  },
}

export const MA = {
  value: 'MA',
  name: 'Massachusetts',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{8}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 8 Numeric', '9 Numeric'],
  },
}

export const MD = {
  value: 'MD',
  name: 'Maryland',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{12}$',
    description: ['1Alpha+12Numeric'],
  },
}

export const ME = {
  value: 'ME',
  name: 'Maine',
  idPattern: {
    rule: '(^[0-9]{7,8}$)|(^[0-9]{7}[A-Za-z]{1}$)',
    description: ['7-8 Numeric', '7 Numeric + 1 Alpha'],
  },
}

export const MI = {
  value: 'MI',
  name: 'Michigan',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{10}$)|(^[A-Za-z]{1}[0-9]{12}$)',
    description: ['1 Alpha + 10 Numeric', '1 Alpha + 12 Numeric'],
  },
}

export const MO = {
  value: 'MO',
  name: 'Missouri',
  idPattern: {
    rule:
      '(^[A-Za-z]{1}[0-9]{5,9}$)|(^[A-Za-z]{1}[0-9]{6}[R]{1}$)|(^[0-9]{8}[A-Za-z]{2}$)|(^[0-9]{9}[A-Za-z]{1}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 5-9 Numeric',
      "1 Alpha + 6 Numeric + 'R'",
      '8 Numeric + 2 Alpha',
      '9 Numeric + 1 Alpha',
      '9 Numeric',
    ],
  },
}

export const MN = {
  value: 'MN',
  name: 'Minnesota',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{12}$',
    description: ['1 Alpha + 12 Numeric'],
  },
}

export const MS = {
  value: 'MS',
  name: 'Mississippi',
  idPattern: {
    rule: '^[0-9]{9}$',
    description: ['9 Numeric'],
  },
}

export const MT = {
  value: 'MT',
  name: 'Montana',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{8}$)|(^[0-9]{13}$)|(^[0-9]{9}$)|(^[0-9]{14}$)',
    description: [
      '1 Alpha + 8 Numeric',
      '13 Numeric',
      '9 Numeric',
      '14 Numeric',
    ],
  },
}

export const NC = {
  value: 'NC',
  name: 'North Carolina',
  idPattern: {
    rule: '^[0-9]{1,12}$',
    description: ['1-12 Numeric'],
  },
}

export const ND = {
  value: 'ND',
  name: 'North Dakota',
  idPattern: {
    rule: '(^[A-Za-z]{3}[0-9]{6}$)|(^[0-9]{9}$)',
    description: ['3 Alpha + 6 Numeric', '9 Numeric'],
  },
}

export const NE = {
  value: 'NE',
  name: 'Nebraska',
  idPattern: {
    rule: '^[0-9]{1,7}$',
    description: ['1-7 Numeric'],
  },
}

export const NH = {
  value: 'NH',
  name: 'New Hampshire',
  idPattern: {
    rule: '^[0-9]{2}[A-Za-z]{3}[0-9]{5}$',
    description: ['2 Numeric + 3 Alpha + 5 Numeric'],
  },
}

export const NJ = {
  value: 'NJ',
  name: 'New Jersey',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{14}$',
    description: ['1 Alpha + 14 Numeric'],
  },
}

export const NM = {
  value: 'NM',
  name: 'New Mexico',
  idPattern: {
    rule: '^[0-9]{8,9}$',
    description: ['8 Numeric', '9 Numeric'],
  },
}

export const NV = {
  value: 'NV',
  name: 'Nevada',
  idPattern: {
    rule: '(^[0-9]{9,10}$)|(^[0-9]{12}$)|(^[X]{1}[0-9]{8}$)',
    description: ['9 Numeric', '10 Numeric', '12 Numeric', "'X' + 8 Numeric"],
  },
}

export const NY = {
  value: 'NY',
  name: 'New York',
  idPattern: {
    rule:
      '(^[A-Za-z]{1}[0-9]{7}$)|(^[A-Za-z]{1}[0-9]{18}$)|(^[0-9]{8}$)|(^[0-9]{9}$)|(^[0-9]{16}$)|(^[A-Za-z]{8}$)',
    description: [
      '1 Alpha + 7 Numeric',
      '1 Alpha + 18 Numeric',
      '8 Numeric',
      '9 Numeric',
      '16 Numeric',
      '8 Alpha',
    ],
  },
}

export const OH = {
  value: 'OH',
  name: 'Ohio',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{4,8}$)|(^[A-Za-z]{2}[0-9]{3,7}$)|(^[0-9]{8}$)',
    description: [
      '1 Alpha + 4-8 Numeric',
      '2 Alpha + 3-7 Numeric',
      '8 Numeric',
    ],
  },
}

export const OK = {
  value: 'OK',
  name: 'Oklahoma',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{9}$)|(^[0-9]{9}$)',
    description: ['1 Alpha + 9 Numeric', '9 Numeric'],
  },
}

export const OR = {
  value: 'OR',
  name: 'Oregon',
  idPattern: {
    rule: '^[0-9]{1,9}$',
    description: ['1-9 Numeric'],
  },
}

export const PA = {
  value: 'PA',
  name: 'Pennsylvania',
  idPattern: {
    rule: '^[0-9]{8}$',
    description: ['8 Numeric'],
  },
}

export const RI = {
  value: 'RI',
  name: 'Rhode Island',
  idPattern: {
    rule: '^([0-9]{7}$)|(^[A-Za-z]{1}[0-9]{6}$)',
    description: ['7 Numeric', '1 Alpha + 6 Numeric'],
  },
}

export const SC = {
  value: 'SC',
  name: 'South Carolina',
  idPattern: {
    rule: '^[0-9]{5,11}$',
    description: ['5-11 Numeric'],
  },
}

export const SD = {
  value: 'SD',
  name: 'South Dakota',
  idPattern: {
    rule: '(^[0-9]{6,10}$)|(^[0-9]{12}$)',
    description: ['6-10 Numeric', '12 Numeric'],
  },
}

export const TN = {
  value: 'TN',
  name: 'Tennessee',
  idPattern: {
    rule: '^[0-9]{7,9}$',
    description: ['7-9 Numeric'],
  },
}

export const TX = {
  value: 'TX',
  name: 'Texas',
  idPattern: {
    rule: '^[0-9]{7,8}$',
    description: ['7-8 Numeric'],
  },
}

export const UT = {
  value: 'UT',
  name: 'Utah',
  idPattern: {
    rule: '^[0-9]{4,10}$',
    description: ['4-10 Numeric'],
  },
}

export const VA = {
  value: 'VA',
  name: 'Virginia',
  idPattern: {
    rule: '(^[A-Za-z]{1}[0-9]{8,11}$)|(^[0-9]{9}$)',
    description: [
      '1 Alpha + 8 Numeric',
      '1 Alpha + 9 Numeric',
      '1 Alpha + 10 Numeric',
      '1 Alpha + 11 Numeric',
      '9 Numeric',
    ],
  },
}

export const VI = {
  value: 'VI',
  name: 'Virgin Islands',
  idPattern: {
    rule: '',
    description: [''],
  },
}

export const VT = {
  value: 'VT',
  name: 'Vermont',
  idPattern: {
    rule: '(^[0-9]{8}$)|(^[0-9]{7}[A]$)',
    description: ['8 Numeric', "7 Numeric + 'A'"],
  },
}

export const WA = {
  value: 'WA',
  name: 'Washington',
  idPattern: {
    rule: '^(?=.{12}$)[A-Za-z]{1,7}[A-Za-z0-9\\*]{4,11}$',
    description: [
      '1-7 Alpha + any combination of Alpha, Numeric, and * for a total of 12 characters',
    ],
  },
}

export const WI = {
  value: 'WI',
  name: 'Wisconsin',
  idPattern: {
    rule: '^[A-Za-z]{1}[0-9]{13}$',
    description: ['1 Alpha + 13 Numeric'],
  },
}

export const WV = {
  value: 'WV',
  name: 'West Virginia',
  idPattern: {
    rule: '(^[0-9]{7}$)|(^[A-Za-z]{1,2}[0-9]{5,6}$)',
    description: ['7 Numeric', '1-2 Alpha + 5-6 Numeric'],
  },
}

export const WY = {
  value: 'WY',
  name: 'Wyoming',
  idPattern: {
    rule: '^[0-9]{9,10}$',
    description: ['9-10 Numeric'],
  },
}

export const UNITED_STATES = [
  AL,
  AK,
  AZ,
  AR,
  CA,
  CO,
  CT,
  DE,
  DC,
  FL,
  GA,
  HI,
  ID,
  IL,
  IN,
  IA,
  KS,
  KY,
  LA,
  ME,
  MD,
  MA,
  MI,
  MN,
  MS,
  MO,
  MT,
  NE,
  NV,
  NH,
  NJ,
  NM,
  NY,
  NC,
  ND,
  OH,
  OK,
  OR,
  PA,
  RI,
  SC,
  SD,
  TN,
  TX,
  UT,
  VT,
  VI,
  VA,
  WA,
  WV,
  WI,
  WY,
]
