import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import moment from 'moment'

class CngDatePicker extends React.Component {
  handleBlur = () => {
    const { onBlur, field } = this.props
    onBlur(field.name)
  }

  handleChange = event => {
    const { onChange, field } = this.props
    onChange(field.name, event || '')
  }

  render () {
    const props = this.props
    return (
      <DatePicker
        {...props}
        fixedHeight
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        selected={props.mydate ? new moment(props.mydate) : null}
        className={
          props.invalid
            ? 'date-picker is-invalid form-control'
            : 'date-picker form-control'
        }
        disabled={props.disabled}
        readOnly={props.readOnly || false}
        disabledKeyboardNavigation
        isClearable={true}
      />
    )
  }

  static propTypes = {
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    field: PropTypes.object.isRequired,
    mydate: PropTypes.object,
    invalid: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool
  }
}

export default CngDatePicker
