import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import { currency } from 'shared/utils'
import RefinanceUserInput from './RefinanceUserInput'
import RefinanceBasicSummary from './RefinanceBasicSummary'
import RefinanceBorrowSummary from './RefinanceBorrowSummary'

class RefinanceBreakdown extends React.Component {
  calculateDays = startDate => {
    var begin = new Date(startDate)
    var today = new Date()
    var timeDiff = Math.abs(today.getTime() - begin.getTime())
    return Math.ceil(timeDiff / (1000 * 3600 * 24))
  }

  calculateTotal = () => {
    const { refinanceLoanDetail, selectedOption, refinanceValue } = this.props

    const baseTotal =
      refinanceLoanDetail.loanAmount + refinanceLoanDetail.financeCharge

    if (selectedOption.refinanceOptionId === 2) {
      return baseTotal - (refinanceValue || 0)
    } else if (selectedOption.refinanceOptionId === 3) {
      return baseTotal + (refinanceValue || 0)
    }

    return baseTotal
  }

  handleSubmit = () => {
    const { onSubmit } = this.props
    onSubmit()
  }

  render () {
    const {
      selectedOption,
      refinanceLoanDetail,
      refinanceValue,
      handleUserInput,
    } = this.props

    return (
      <React.Fragment>
        <React.Fragment>
          <h2 data-test="text.section-title">{selectedOption.title}</h2>
          <p data-test="text.section.description">
            {selectedOption.description
              .replace('&lt;DUE_DATE&gt;', refinanceLoanDetail.loanDueDate)
              .replace('&lt;NEW_DUE_DATE&gt;', refinanceLoanDetail.newDueDate)}
          </p>
        </React.Fragment>

        <Row className="justify-content-center">
          <Col lg="8" xl="7">
            {selectedOption.refinanceInput && (
              <RefinanceUserInput
                refinanceInput={selectedOption.refinanceInput}
                refinanceValue={refinanceValue}
                handleUserInput={handleUserInput}
              />
            )}

            <div className="text-center">
              <Button
                id="refinance_breakdown--button-continue"
                color="primary"
                onClick={this.handleSubmit}
              >
                Continue
              </Button>
            </div>

            <h3 className="mb-2">Refinance Summary</h3>

            {selectedOption.refinanceOptionId !== 3 && (
              <RefinanceBasicSummary
                daysUntilDue={this.calculateDays(
                  refinanceLoanDetail.loanDueDate
                )}
                refinanceLoanDetail={refinanceLoanDetail}
                refinanceOptionId={selectedOption.refinanceOptionId}
                refinanceValue={refinanceValue}
              />
            )}

            {selectedOption.refinanceOptionId === 3 && (
              <RefinanceBorrowSummary
                refinanceLoanDetail={refinanceLoanDetail}
                refinanceValue={refinanceValue}
              />
            )}

            <p className="mb-0 mt-3">
              <strong>
                Amount due on {refinanceLoanDetail.newDueDate}{' '}
                <span className="text-nowrap">
                  ({this.calculateDays(refinanceLoanDetail.newDueDate)} days)
                </span>
              </strong>
            </p>

            <div className="d-flex">
              <span>Original loan amount</span>
              <span className="ml-auto">
                <strong>{currency(refinanceLoanDetail.loanAmount, 2)}</strong>
              </span>
            </div>

            {selectedOption.refinanceOptionId === 2 && (
              <React.Fragment>
                <div className="d-flex">
                  <span>Additional amount paid back</span>
                  <span className="ml-auto">
                    <strong>-{currency(refinanceValue || 0, 2)}</strong>
                  </span>
                </div>
                <div className="d-flex">
                  <span>Adjusted loan amount</span>
                  <span className="ml-auto">
                    <strong>
                      {currency(
                        refinanceLoanDetail.loanAmount - (refinanceValue || 0),
                        2
                      )}
                    </strong>
                  </span>
                </div>
              </React.Fragment>
            )}

            {selectedOption.refinanceOptionId === 3 && (
              <React.Fragment>
                <div className="d-flex">
                  <span>Additional amount requested</span>
                  <span className="ml-auto">
                    <strong>+{currency(refinanceValue || 0, 2)}</strong>
                  </span>
                </div>
                <div className="d-flex">
                  <span>New loan amount</span>
                  <span className="ml-auto">
                    <strong>
                      {currency(
                        refinanceLoanDetail.loanAmount + (refinanceValue || 0),
                        2
                      )}
                    </strong>
                  </span>
                </div>
              </React.Fragment>
            )}

            <div className="d-flex">
              <span>New finance charge</span>
              <span className="ml-auto">
                <strong>
                  +{currency(refinanceLoanDetail.financeCharge, 2)}
                </strong>
              </span>
            </div>

            <hr />

            <div className="d-flex">
              <span>Total amount due</span>
              <span className="ml-auto">
                <strong>{currency(this.calculateTotal(), 2)}</strong>
              </span>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}

RefinanceBreakdown.propTypes = {
  selectedOption: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    refinanceOptionId: PropTypes.number.isRequired,
    refinanceInput: PropTypes.shape({
      title: PropTypes.string,
      link: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
  }).isRequired,
  refinanceLoanDetail: PropTypes.shape({
    loanAmount: PropTypes.number.isRequired,
    loanFeeAmount: PropTypes.number.isRequired,
    loanDueDate: PropTypes.string.isRequired,
    newDueDate: PropTypes.string.isRequired,
    financeCharge: PropTypes.number.isRequired,
  }).isRequired,
  refinanceValue: PropTypes.number,
  handleUserInput: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
}

export default RefinanceBreakdown
