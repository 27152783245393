import gql from 'graphql-tag'

export const AUTHENTICATION_QUERY = gql`
  query authentication {
    authentication @client {
      email
      adminEmail
      isAuthenticated
      isAuthenticating
      isLegacyAuthenticated
      isAdmin
      isReturningCustomer
      isRetailReturning
      isCoreCustomer
      error
      boCode
      retailBoCode
      loanProCustomerId
      qFundCustomerId
      transient
      firstName
      lastName
      isEnrollment
      customBrand
      hasOnlinePendingOrigination
      hasPendingApplication
      pendingApplicationBrand
      customerType
      stateCode
      channel
    }
  }
`

export const UPDATE_AUTHENTICATION_QUERY = gql`
  mutation updateAuthentication(
    $email: String!
    $adminEmail: String
    $isAuthenticated: Boolean
    $isLegacyAuthenticated: Boolean
    $isAuthenticating: Boolean
    $isAdmin: Boolean
    $isReturningCustomer: Boolean
    $isRetailReturning: Boolean
    $isCoreCustomer: Boolean
    $error: String
    $boCode: String
    $retailBoCode: String
    $loanProCustomerId: String
    $qFundCustomerId: String
    $customSource: String
    $customBrand: String
    $transient: String
    $firstName: String
    $lastName: String
    $isEnrollment: Boolean
    $customBrand: String
    $hasOnlinePendingOrigination: Boolean
    $hasPendingApplication: Boolean
    $pendingApplicationBrand: String
    $customerType: String
    $stateCode: String
    $channel: String
  ) {
    updateAuthentication(
      email: $email
      adminEmail: $adminEmail
      isAuthenticated: $isAuthenticated
      isLegacyAuthenticated: $isLegacyAuthenticated
      isAuthenticating: $isAuthenticating
      isAdmin: $isAdmin
      isReturningCustomer: $isReturningCustomer
      isRetailReturning: $isRetailReturning
      isCoreCustomer: $isCoreCustomer
      error: $error
      boCode: $boCode
      loanProCustomerId: $loanProCustomerId
      qFundCustomerId: $qFundCustomerId
      retailBoCode: $retailBoCode
      customSource: $customSource
      customBrand: $customBrand
      transient: $transient
      firstName: $firstName
      lastName: $lastName
      isEnrollment: $isEnrollment
      customBrand: $customBrand
      hasOnlinePendingOrigination: $hasOnlinePendingOrigination
      hasPendingApplication: $hasPendingApplication
      pendingApplicationBrand: $pendingApplicationBrand
      customerType: $customerType
      stateCode: $stateCode
      channel: $channel
    ) @client {
      email
      adminEmail
      isAuthenticated
      isAuthenticating
      isLegacyAuthenticated
      isAdmin
      isReturningCustomer
      isRetailReturning
      isCoreCustomer
      error
      boCode
      retailBoCode
      loanProCustomerId
      qFundCustomerId
      transient
      firstName
      lastName
      isEnrollment
      customBrand
      hasOnlinePendingOrigination
      hasPendingApplication
      pendingApplicationBrand
      customerType
      stateCode
      channel
    }
  }
`

export const CUSTOMER_SYSTEM_SEARCH_QUERY = gql`
  query customerSystemSearch {
    customerSystemSearch {
      hasOpenApplication
      isReturningRetailCustomer
      isReturningCustomer
      isCoreCustomer
      hasOnlinePendingOrigination
      hasPendingApplication
      pendingApplicationBrand
      customerInformation {
        personalInformation {
          firstName
          lastName
        }
        contactInformation {
          address {
            stateCode
          }
        }
      }
    }
  }
`

export const SKIP_RESUME_APPLICATION_QUERY = gql`
  query skipResumeApplication($email: String!) {
    skipResumeApplication(email: $email)
  }
`
