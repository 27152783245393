import React from 'react'
import PropTypes from 'prop-types'
import { MapBodyParagraph } from '../ModalUtilities'

import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'

const DefaultModal = ({
  modalId = 'default-modal',
  cancelText,
  continueText,
  isOpen,
  onClose,
  onCancel,
  onContinue,
  header,
  body,
  size,
  onContinueDisabled = false,
}) => {
  return (
    <Modal isOpen={isOpen} size={size}>
      <ModalHeader data-test={`${modalId}-header`} toggle={onClose}>
        {header}
      </ModalHeader>
      <ModalBody>
        <MapBodyParagraph body={body} />
      </ModalBody>
      <ModalFooter>
        {onCancel && (
          <Button
            id={`${modalId}--button-cancel`}
            color="primary"
            outline
            onClick={onClose}
            data-test="buttons.authorize.close"
          >
            {cancelText || 'Cancel'}
          </Button>
        )}

        <Button
          id={`${modalId}--button-continue`}
          color="primary"
          onClick={onContinue}
          data-test="buttons.authorize.submit"
          className={!onCancel ? 'w-100' : ''}
          disabled={onContinueDisabled}
        >
          {continueText || 'Continue'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

DefaultModal.propTypes = {
  modalId: PropTypes.string,
  cancelText: PropTypes.string,
  continueText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onContinue: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  header: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.number,
  onContinueDisabled: PropTypes.bool,
}

export default DefaultModal
