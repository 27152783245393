import React from 'react'
import PropTypes from 'prop-types'
import authService from 'authentication/service'
import { LoadingScreen } from 'shared/components'
import { setCookie, getCookie, setCookieToExpire } from 'shared/cookie'
import Branding from 'shared/branding'
import cognito from 'shared/cognito'
import { saveError } from 'shared/sentry'
import { buildUrlWithParams, getParamsFromString } from './helper'

const { REACT_APP_P360_LOGIN: p360Domain } = process.env

class PartnerSiteRedirect extends React.Component {
  componentDidMount () {
    const {
      brand,
      authProps,
      enableXact,
      redirectToP360,
      isAppRedirect,
      location,
    } = this.props
    let email = null
    if (authProps && authProps.isAdmin) {
      const key = `cng.authAs.${authProps.adminEmail}`
      email = localStorage.getItem(key) || null
    }

    let baseUrl = null
    const search = getParamsFromString(location.search)
    const hash = getParamsFromString(location.hash)

    const showWelcomeModal = !!getCookie(document, 'showWelcomeModal')
    setCookieToExpire('showWelcomeModal')
    if (showWelcomeModal) {
      search.push('nonKioskEnrollemnt=1')
    }

    if (brand && !enableXact) {
      const brandConfig = Branding.getBrand(brand)
      if (brandConfig) {
        baseUrl = `${redirectToP360 ? p360Domain : brandConfig.loginUrl}`
        search.push('brandRedirect=true')
        hash.push('sso=brand')
      } else {
        saveError(`Can't find brand configuration for ${brand}`)
      }

      if (baseUrl) {
        if (email) {
          setCookie(document, '', 'adminAuthAs', email, 60)
        }
        setCookie(document, '', 'id_token', cognito.idToken, 60)
        setCookie(document, '', 'access_token', cognito.accessToken, 60)
      }
    } else {
      baseUrl = this.getBaseRedirectUrl()

      search.push('brandRedirect=true')

      if (redirectToP360 && !isAppRedirect) {
        search.push('redirectToP360=true')
      }

      if (isAppRedirect) {
        search.push('resumeApp=true')
      }
    }

    const redirectTo = buildUrlWithParams(baseUrl, { search, hash }, authProps)
    window.localStorage.clear()
    window.location.href = redirectTo
    authService.signOut(false, true)
  }

  getBaseRedirectUrl () {
    const {
      redirectToBrand,
      redirectToP360,
      isAppRedirect,
      location,
    } = this.props

    if (redirectToP360 && !isAppRedirect) {
      let p360DomainUrl = p360Domain
      const knownUrlPathToRedirect = ['authentication', 'enrollment', 'resume']
      const pathUrlParts = location.pathname.split('/')
      const matchingPaths = pathUrlParts.filter(path =>
        knownUrlPathToRedirect.includes(path)
      )

      if (matchingPaths.length > 0)
        p360DomainUrl = p360DomainUrl.replace('/login', location.pathname)
      return p360DomainUrl
    } else if (redirectToBrand) {
      const { loginUrl } = Branding.getBrand(redirectToBrand)
      return loginUrl
    } else {
      const { partnerSiteUrl } = Branding.current
      return partnerSiteUrl
    }
  }

  render () {
    const { isInline } = this.props
    return (
      <LoadingScreen inline={isInline}>
        Transferring to partner site
      </LoadingScreen>
    )
  }
}

PartnerSiteRedirect.propTypes = {
  brand: PropTypes.string,
  enableXact: PropTypes.bool.isRequired,
  redirectToBrand: PropTypes.string,
  redirectToP360: PropTypes.bool,
  authProps: PropTypes.shape({
    isAdmin: PropTypes.bool.isRequired,
    adminEmail: PropTypes.string,
  }),
  isAppRedirect: PropTypes.bool,
  isInline: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    hash: PropTypes.string,
    pathname: PropTypes.string
  })

}

export default PartnerSiteRedirect
