import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'reactstrap'
import { breadCrumbSections } from './constants'
import apolloClient from 'shared/apolloClient'
import { AUTHENTICATION_QUERY } from 'authentication/queries'
import Branding from 'shared/branding'
import {
  CHOICE_LOAN,
  ONLINE_STORE_BRAND_NAME,
  TRUSTPILOT_BUSINESS_UNIT_IDS,
} from '../../../constants/values'
import TrustPilotWidget from '../TrustPilotWidget'
import { APPLICATION_PATHS } from '../../../constants/paths'

const calculateSectionDistance = (sectionLength, index) => {
  return (100 / sectionLength) * (index + 1)
}

const mapBreadCrumbs = (isReturnCustomer, productCode) => {
  let sections
  if (isReturnCustomer && productCode !== CHOICE_LOAN) {
    sections = breadCrumbSections.filter(
      section => section.name !== 'Loan Details'
    )
  } else {
    sections = breadCrumbSections
  }
  return sections.map((section, index) => ({
    ...section,
    id: index + 1,
    isActive: false,
    distance:
      index === 0
        ? '-1em'
        : `${calculateSectionDistance(sections.length, index)}%`,
  }))
}

const handleProgression = (sections, path) => {
  const found = sections.find(section => section.paths.includes(path))

  return sections.map(section => ({
    ...section,
    isActive: section.id === found?.id,
  }))
}

const ApplicationBreadCrumb = ({ productCode }) => {
  const [sections, setSections] = useState(null)
  const pathName = useRef(null)
  const { currentBranding } = Branding

  const getSections = (auth, path) => {
    const isReturning = auth?.isReturningCustomer

    const breadCrumbSections = mapBreadCrumbs(
      isReturning,
      productCode,
    )
    const progression = handleProgression(breadCrumbSections, path)

    pathName.current = path
    setSections(progression)
  }

  useEffect(() => {
    const path = window.location.pathname
    const hasPathChanged = path !== pathName.current

    if (!sections || path !== hasPathChanged) {
      apolloClient
        .query({
          query: AUTHENTICATION_QUERY,
        })
        .then(authData => {
          getSections(authData?.data?.authentication, path)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname])

  let activeIndex
  if (sections && sections.length) {
    const active = sections.findIndex(section => section.isActive)
    activeIndex = active
  }

  return (
    <div className="overflow-hidden">
      <div className="">
        <div data-test="top-section">
          <div data-test="progress-steps">
            {currentBranding.key !== ONLINE_STORE_BRAND_NAME.ACA &&
              activeIndex === 0 &&
              window.location.pathname.includes(APPLICATION_PATHS.ACCOUNT) && (
                <Row className="bg-light d-lg-none">
                  <Col className="col-xs-12">
                    <div
                      style={{
                        paddingTop: '0.2rem',
                        width: '90%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                    >
                      <TrustPilotWidget
                        businessUnitID={
                          TRUSTPILOT_BUSINESS_UNIT_IDS[currentBranding.key]
                        }
                      />
                    </div>
                  </Col>
                </Row>
              )}
            <Container>
              <Row className="d-lg-none pt-4 d-flex justify-content-center">
                <Col className="mobile-progress-bar col-10 ">
                  <Col
                    style={{
                      left: `${sections &&
                        sections.length &&
                        typeof activeIndex === 'number' &&
                        activeIndex >= 0 &&
                        sections[activeIndex]?.distance}`,
                    }}
                    className="mobile-progress-info"
                  >
                    <span>
                      {typeof activeIndex === 'number' && activeIndex + 1} of{' '}
                      {sections && sections.length}
                    </span>
                    <Col className="mobile-progress-circle" />
                  </Col>
                </Col>
              </Row>
              <Row
                className="d-lg-flex justify-content-center d-none mx-auto breadcrumb-desktop-container ">
                {sections?.map((section, index) => {
                  const len = sections.length - 1
                  const isFinalSection = index === len

                  return (
                    <div
                      key={section.id}
                      className={`d-flex flex-row justify-content-around px-0 align-items-center ${index ===
                        len - 1 && 'pr-0'}  ${!isFinalSection ? 'col-2 col' : 'px-0 last-step-width'}`}
                    >
                      <Col
                        className={`d-flex flex-column align-items-center justify-content-center  ${!isFinalSection ? 'col-3' : 'col-1'
                          } ${section.isActive ? 'current-step' : 'progress-step'
                          }`}
                        key={index}
                        data-test={section.name}
                      >
                        <span
                          className="step_name"
                          style={{
                            width: section.width,
                          }}
                        >
                          {section.name}
                        </span>
                        <span
                          data-test="text.progress-step"
                          className="step_number"
                        >
                          {section.id}
                        </span>
                      </Col>
                      {!isFinalSection && (
                        <Col
                          xl={{ size: 8 }}
                          lg={{
                            size: 8,
                          }}
                          className={`border border-dark breadcrumb-bar ml-xl-0 `}
                        />
                      )}

                    </div>
                  )
                })}
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </div>
  )
}

ApplicationBreadCrumb.propTypes = {
  productCode: PropTypes.string,
  applicationType: PropTypes.string
}

export default ApplicationBreadCrumb
