export const GLOBAL_PATHS = {
  ROOT: '/',
  ERROR: '/error',
  LOGIN: '/login',
  PENDING: '/pending',
  APPLICATION: '/application',
  FORGOT_PASSWORD: '/authentication/forgot-password',
  FORGOT_EMAIL: '/authentication/forgot-email',
  FORGOT_EMAIL_FAILED: '/authentication/forgot-email/fail',
  FORGOT_PASSWORD_FAILED: '/authentication/forgot-password/fail',
  CHANGE_PASSWORD: '/authentication/change-password/:code/:email',
  LOGIN_SUCCESSFUL: '/login-successful',
  MFA: '/authentication/mfa',
  MFA_FAIL: '/authentication/mfa/fail'
}

export const ADMIN_PATHS = {
  LOGIN: '/admin/login',
  LANDING: '/admin/landing',
}

export const ACCOUNT_PATHS = {
  ACCOUNT: '/account',
  ERROR: '/account/error',
  PERSONAL: '/account/personal',
  OPEN_LOAN: '/account/open-loan',
  OPEN_LOAN_PAST_DUE: '/account/open-loan-past-due',
  NO_OPEN_LOAN: '/account/no-open-loan',
  CONDITIONALLY_APPROVED_LOAN: '/account/conditionally-approved-loan',
  APPLY_NOW: '/account/apply-now',
  CONVERSION: '/account/conversion',
  CONVERSION_LEARN_MORE: '/account/conversion/learn-more',
  REFINANCE: '/account/refinance',
  LOAN_MODIFICATION: '/account/loan-modification',
  REFINANCE_CONVERSION: '/account/refinance-conversion',
  ELIGIBLE_TO_REFINANCE: '/account/refinance-consolidation/refinance',
  ELIGIBLE_TO_CONSOLIDATE: '/account/refinance-consolidation/consolidate',
  ORIGINATION_ON_HOLD: 'account/origination-on-hold',
  PENDING_REFINANCE: '/account/pending-refinance',
  PENDING_EPP: '/account/pending-epp',
  PENDING_LOAN_MODIFICATION: '/account/pending-loan-modification',
  REFI_PENDING_ORIGINATION: 'account/refinance-pending-origination',
  REPAY: '/account/repay',
  PAYMENT_LOANPRO: '/account/payment-loanpro',
  PAYMENT_LOANPRO_DEFAULT: '/account/payment-loanpro-default',
  IVR: '/account/ivr',
  PAYMENT: '/account/payment',
  LOAN_IN_RESCISSION_PROCESS: '.account/loan-in-rescission',
}

export const APPLICATION_PATHS = {
  ACCOUNT: '/application/account',
  AGRREEMENTS_NOT_READY: '/application/agreements/not-ready',
  PERSONAL: '/application/personal',
  RESUME: '/application/resume',
  NOT_IN_STATE: '/application/not-in-state',
  PRODUCT_PREFERENCE: '/application/product-preference',
  STORE_SELECT: '/application/store-select',
  NOT_ELEGIBLE: '/application/not-eligible',
  CONTINUE_CONVERSION: '/application/continue-conversion',
  CONVERSION_ELIGIBLE_ENROLLMENT: '/application/conversion-eligible-enrollment',
  FORCE_ENROLLMENT: '/application/force-enrollment',
  INCOME: '/application/income',
  BANK: '/application/bank',
  BANK_MANUAL: '/application/bank/manual',
  PLAID_BANK_AVAILABLE: '/application/bank/plaid',
  PLAID_BANK_CONSENT: '/application/bank/plaid/consent',
  PLAID_BANK_LAUNCHED: '/application/bank/plaid/open',
  PLAID_BANK_VERIFY: '/application/bank/plaid/verify',
  PLAID_BANK_ACCOUNT_SELECTION: '/application/bank/plaid/account-select',
  PLAID_ERROR_INITIALIZING: '/application/bank/plaid/error-initializing',
  PLAID_INVALID_CREDENTIALS: '/application/bank/plaid/invalid-credentials',
  PLAID_NO_AUTH_PRODUCT: '/application/bank/plaid/no-auth',
  PLAID_NO_PRODUCT_DATA: '/application/bank/plaid/no-data',
  PLAID_NO_CHECKING: '/application/bank/plaid/no-checking',
  LEAD_AGREEMENTS: '/application/approved/agreements',
  NOT_APPROVED_LEAD_WELCOME: '/application/welcome',
  OTP: '/application/otp',
  PRODUCTS: '/application/products',
  AMOUNT: '/application/amount',
  BANK_VERIFICATION: '/application/bank-verification',
  UPLOAD_DOCS: '/application/upload-docs',
  SUBMIT: '/application/submit',
  TRANSITION: '/application/transition',
  ESIGN: '/application/esign',
  HOLD_ESIGN: '/application/hold-esign',
  THANK_YOU: '/application/thank-you',
  AGREEMENTS: '/application/agreements',
  ADVERSE_ACTION: '/application/adverse-action',
  ADVERSE_ACTION_RETAIL: '/application/adverse-action/retail',
  ADVERSE_ACTION_THANK_YOU: '/application/adverse-action/thank-you',
  ADVERSE_ACTION_KEEP_IN_MIND: '/application/adverse-action/keep-in-mind',
  ANOTHER_OPTION_SELECTED: '/application/another-option-selected',
  INTELLICHECK: '/application/identity',
  INTELLICHECK_LANDING: '/application/identity/verify',
  INTELLICHECK_OPT_OUT: '/application/identity/opt-out',
  VERIFICATION_FAILED: '/mfa/verification-failed',
  RESUME_PASSWORDLESS: '/resume'
}

export const PATHS_WITH_PARAMS = [
  {
    alias: 'change-password',
    fullPath: '/authentication/change-password',
  },
]

export const LOAN_PATHS = {
  CONVERSION: '/loan/conversion',
  CONVERT: '/loan/conversion/convert',
  REFINANCE: '/loan/conversion/refinance',
}

export const ENROLLMENT_PATHS = {
  ENROLLMENT: '/enrollment',
  ERROR: '/enrollment/error',
  NO_RECORD_LOCATED: '/enrollment/no-record-located',
  CUSTOMER_IN_USE: '/enrollment/customer-already-in-use',
  EMAIL_IN_USE: '/enrollment/email-already-in-use',
  EMAIL_VERIFICATION: '/enrollment/email-verification',
  EMAIL_VERIFICATION_FAILED: '/enrollment/email-verification-failed',
  EMAIL_VERIFICATION_NOT_ELIGIBLE:
    '/enrollment/email-verification-not-eligible',
  ENROLLMENT_RESIDENTIAL_FORM: '/enrollment/residential-form',
  OTP_ELIGIBLE: '/enrollment/otp-send-code',
  OTP_VERIFY_CODE: '/enrollment/otp-verify-code',
  OTP_FAILED: '/enrollment/otp-failed',
  VERIFICATION_SUCCESS: '/enrollment/success',
  THANK_YOU: '/enrollment/thank-you',
  ENROLLMENT_PERSONAL_FORM: '/enrollment/personal-form',
}

export const MFA_VIRTUAL_PATHS = {
  REMEMBER_DEVICE: '/mfa/remember-device',
  REMEMBER_DEVICE_ACCEPT: '/mfa/remember-device-accept',
  REMEMBER_DEVICE_DECLINE: '/mfa/remember-device-decline',
  MFA_PHONE: '/mfa/otp-phone',
  MFA_EMAIL: '/mfa/otp-email',
  MFA_BOTH: '/mfa/otp-phone-email',
  MFA_BYPASS: '/mfa/bypass',
  MFA_APPROVED: '/mfa/approved',
  MFA_DENIED: '/mfa/denied',
}

export const ACCOUNT_BENEFITS_AND_REWARDS_PATHS = {
  TU_CREDITVIEW: '/account/benefits-and-rewards/tu-creditview',
}
