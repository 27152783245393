import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import browserHistory from 'shared/browserHistory'
import apolloClient from 'shared/apolloClient'
import { ErrorBoundary } from 'shared/components'
import 'shared/consoleTools'
import PageView from 'shared/PageView'
import App from './App'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import '@uppy/core/dist/style.min.css'
import '@uppy/dashboard/dist/style.min.css'

const LAUNCH_DARKLY_CLIENT_KEY = process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_KEY

PageView.listenToBrowserHistory(browserHistory)

var Intl = window.Intl
if (typeof Intl === 'undefined') {
  window.Intl = Intl = {}
}
if (!Intl.NumberFormat) {
  Intl.NumberFormat = function(_locale, config) {
    let prefix = config.style === 'currency' ? '$' : ''
    return {
      format: function(amount) {
        return prefix + amount
      },
    }
  }
}

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: LAUNCH_DARKLY_CLIENT_KEY,
    context: {
      kind: "user",
      key: 'boss-web',
    },
  })

  ReactDOM.render(
    <LDProvider>
      <Router history={browserHistory}>
        <ApolloProvider client={apolloClient}>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ApolloProvider>
      </Router>
    </LDProvider>,
    document.getElementById('root')
  )
})()

export default apolloClient
