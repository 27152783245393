import React from 'react'
import Branding from 'shared/branding'
import { BRANDS } from 'constants/values'
import {
  Badge,
  Popover,
  PopoverBody,
  NavItem,
  NavLink,
} from 'reactstrap'
import ChevronNavLink, { Directions } from './ChevronNavLink'
import PropTypes from 'prop-types'

const PopupMenu = ({ isOpen, toggle, remoteSessionToggle, remoteSessionOption }) => {
  const branding = Branding.current
  const navLinkComponent = (brandLink, badgeText, linkTest, text, badgeClass) => <NavItem className="d-flex align-items-center">
    <NavLink
      target="_blank"
      href={branding[brandLink]}
      data-test={badgeText}
      className="text-nowrap pr-0"
    >
      <Badge color={branding.headerColor} pill className="circle smallBadge">
        <i
          className={badgeClass}
          alt="store"
          data-test="images.store-icon" />
      </Badge>
    </NavLink>
    <NavLink
      target="_blank"
      href={branding[brandLink]}
      data-test={linkTest}
      className="text-nowrap"
      style={{ color: '#454545' }}
    >
      {text}
    </NavLink>
  </NavItem>

  return (
    <Popover className="popOverOveride overflow-hidden" hideArrow={true} placement="bottom" isOpen={isOpen} target="toggler"  >
      <NavLink target="_blank" style={{ paddingRight: '10px', paddingTop: '15px', cursor: 'pointer' }} onClick={toggle} className="close" data-dismiss="alert">&times;</NavLink>
      <PopoverBody style={{ minWidth: '350px' }}>
        {(branding.key === BRANDS.ACA || branding.key === BRANDS.CNG) && (
          navLinkComponent("findAStore", "text.findAStoreBadge", "text.findAStoreLink", "Find a Store", "fas fa-map-marker-alt fa-fw fa-2x")
        )}
        {branding.key === BRANDS.CCB && (
          navLinkComponent("howItWorksLink", "text.howItWorksBadge", "text.howItWorksLink", "How it works", "fas fa-cog fa-fw fa-2x")
        )}
        {navLinkComponent("getAnswersLink", "text.getAnswersBadge", "text.getAnswersLink", "Get Answers", "fas fa-question fa-fw fa-2x")}
        <NavItem className="d-flex align-items-center">
          <NavLink
            target="_blank"
            href={`tel:${branding.phoneNumber}`}
            data-test="text.call-company-phone"
            className="text-nowrap pr-0"
          >
            <Badge color={branding.headerColor} pill className="circle smallBadge">
              <i
                className="fas fa-mobile-alt fa-fw fa-2x"
                alt="phone"
                data-test="images.phone-icon"
              />
            </Badge>
          </NavLink>
          <NavLink
            target="_blank"
            href={`tel:${branding.phoneNumber}`}
            data-test="text.company-phone"
            className="text-nowrap"
            style={{ color: '#454545' }}
          >
            {branding.phoneNumber}
          </NavLink>
          {remoteSessionOption && <ChevronNavLink direction={Directions.RIGHT} onClick={remoteSessionToggle} />}
        </NavItem>
      </PopoverBody>
    </Popover>
  )
}

PopupMenu.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  remoteSessionToggle: PropTypes.func,
  remoteSessionOption: PropTypes.bool
}

export default PopupMenu
