import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'reactstrap'
import { currency, isQfundReleaseFive } from 'shared/utils'
import XactLoanDisclosure from './XactLoanDisclosure'
import CaliforniaNotice from 'shared/components/Footer/CaliforniaNotice'
import Branding from 'shared/branding'
import PatriotActDisclosure from './PatriotActDisclosure'
import { FooterLinks } from 'shared/components'
import { SHOWILPVALUE } from 'shared/components/Footer/ShowIlpConstValue'
import { APPLICATION_PATHS } from 'constants/paths'
import XactIlpTermFooter from './XactILPTermFooter'

const CCBankFooter = ({
  copyrightYear,
  footerLinks,
  showIlp,
  showStandardArticles,
}) => {
  const isQfundR5 = isQfundReleaseFive()
  let counter = 1

  const ilpInfo = showIlp ? showIlp : SHOWILPVALUE
  const { maxAmountForCA } = SHOWILPVALUE
  const path = window.location.pathname
  const shouldNotApplytopMargin =
    path === APPLICATION_PATHS.LEAD_AGREEMENTS ||
    path === APPLICATION_PATHS.NOT_APPROVED_LEAD_WELCOME
  return (
    <footer
      role="contentinfo"
      id="footer"
      className={ shouldNotApplytopMargin ? '' : 'mt-5'}
    >
      <Container>
        <FooterLinks footerLinks={footerLinks} CCBFooter={true} />

        <h3
          data-test="text.online-disclosures-heading"
          id="online-disclosures-header"
        >
          Online Disclosures
        </h3>

        <PatriotActDisclosure />

        <div data-test="etc.online-disclosures" id="footer-content">
          <p>
            <strong>Customer Notice:</strong>
          </p>

          <p>
            {counter++}. The Xact® Loan is an installment loan originated and
            funded by Capital Community Bank, a Utah Chartered bank located in
            Provo, UT, Member FDIC. Xact® loans to California residents have a
            maximum amount financed of {currency(maxAmountForCA, 0)}.
          </p>

          <p data-test="test.disclosures.standardArticles">
            {showStandardArticles && `${counter++}. `}Approval depends upon
            meeting legal, regulatory and underwriting requirements. All times
            and dates are based on Eastern Time (ET). If your loan is approved
            and originated by 8:00 p.m. ET on a business day, your funds will
            typically become available on the next business day. The lender may,
            at their discretion, verify application information by using
            national databases that may provide information from one or more
            national credit bureaus, and the lender may take that into
            consideration in the approval process.
          </p>

          {ilpInfo && showStandardArticles && !isQfundR5 && (
            <XactIlpTermFooter
              counter={counter++}
              examples={ilpInfo.xactExamples}
              data-test="test.disclosures.ilpExample"
            />
          )}

          {ilpInfo && showStandardArticles && isQfundR5 && (
            <p data-test="test.disclosures.ilp">
              {counter++}. Actual loan amount and terms will vary based on 
              final evaluation and credit qualifications. Payment amounts 
              vary by rate.
            </p>
          )}

          <p>
            {counter++}. TransUnion CreditView Dashboard is a third party
            provided service. The material has not been modified or validated by
            Xact®.
          </p>

          <p>
            {counter++}. Payments must be made on time in order to enhance
            credit with lender.
          </p>

          <p>
            Products or services offered to customers may vary based on customer
            eligibility and applicable state or federal law. All available
            products subject to applicable lender’s terms and conditions. Actual
            loan amounts vary.
          </p>

          {!isQfundR5 && (
            <XactLoanDisclosure />
          )}

          <p>
            NEBRASKA RESIDENTS: A credit agreement must be in writing to be
            enforceable under Nebraska law. To protect you (borrower) and us
            (lender) from any misunderstandings or disappointments, any
            contract, promise, undertaking, or offer to forebear repayment of
            money or to make any other financial accommodation in connection
            with this loan of money or grant or extension of credit, or any
            amendment of, cancellation of, waiver of, or substitution for any or
            all of the terms or provisions of any instrument or document
            executed in connection with this loan of money or grant or extension
            of credit, must be in writing to be effective.
          </p>

          <p>
            RHODE ISLAND RESIDENTS: Credit reports - Notice to individual – I
            understand that a credit report may be requested in connection with
            my application for credit.
          </p>

          <p>
            CALIFORNIA RESIDENTS: If you are married, you may apply for an
            account separate from your spouse.
          </p>

          <p>
            ** Xact® doesn&apos;t charge you for using text messages (SMS) for
            two-factor authentication. However, your mobile provider&apos;s
            standard rates for sending and receiving messages may apply.
          </p>

          <p>
            &dagger; By providing your telephone number, including any mobile
            number, you consent that we may call you at these numbers for
            servicing and marketing telephone calls.
          </p>

          <CaliforniaNotice strong={false} brand={Branding.current.key} />

          <span data-test="text.copyright">
            &copy; {copyrightYear} Xact®. All rights reserved.
            <address>PO Box 36454, Cincinnati, OH 45236</address>
          </span>
        </div>
      </Container>
    </footer>
  )
}

CCBankFooter.displayName = 'Footer'
CCBankFooter.propTypes = {
  copyrightYear: PropTypes.number.isRequired,
  stateCode: PropTypes.string,
  showIlp: PropTypes.object,
  footerLinks: PropTypes.array,
  showStandardArticles: PropTypes.bool
}

export default CCBankFooter
