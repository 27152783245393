import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Collapse, Row, Col, Button } from 'reactstrap'

const YourStoreHours = ({ dayOfWeek, hours, isConditionalApproval }) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const [mappedHours, setMappedHours] = useState([])
  const [todayHours, setTodayHours] = useState({})

  const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ]

  useEffect(() => {
    async function mapHours () {
      const hoursMapped = []
      let todayIndex = -1

      hours.forEach((hour, index) => {
        if (dayOfWeek === days[index]) {
          setTodayHours({ dayOfWeek, ...hour })
          todayIndex = index
        } else if (todayIndex !== -1) {
          hoursMapped.push({ dayOfWeek: days[index], ...hour })
        }
      })

      if (todayIndex !== 0) {
        let count = 0
        while (count < todayIndex) {
          hoursMapped.push({ dayOfWeek: days[count], ...hours[count] })
          count++
        }
      }

      setMappedHours(hoursMapped)
    }

    if (mappedHours.length === 0) {
      mapHours()
    }
  })

  const showMore = () => {
    setIsExpanded(!isExpanded)
  }

  if (mappedHours.length) {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <Row key={`current-hours-${dayOfWeek}`}>
              <Col
                xs="5"
                md={isConditionalApproval ? '4' : '2'}
                className="pr-0 font-weight-bold"
              >
                {todayHours.openTime ? `Open today:` : 'Closed today:'}
              </Col>

              <Col
                xs="7"
                md={isConditionalApproval ? '7' : '4'}
                className="p-0 font-weight-bold"
              >
                {todayHours.openTime
                  ? `${todayHours.openTime} - ${todayHours.closeTime}  `
                  : 'Show hours'}

                {isExpanded && (
                  <Button onClick={showMore} color="link" className="pl-1">
                    <i
                      className="fas fa-chevron-up icon-black pl-1"
                      onClick={showMore}
                    />
                  </Button>
                )}
                {isExpanded || (
                  <Button onClick={showMore} color="link" className="pl-1">
                    <i
                      className="fas fa-chevron-down icon-black pl-1"
                      onClick={showMore}
                    />
                  </Button>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Collapse isOpen={isExpanded}>
          {mappedHours.map(({ dayOfWeek, openTime, closeTime }) => {
            const time = openTime ? `${openTime} - ${closeTime}` : 'Closed'
            return (
              <Row key={`other-hours-${dayOfWeek}`}>
                <Col
                  xs="5"
                  md={isConditionalApproval ? '4' : '2'}
                  className="pr-0"
                >
                  {dayOfWeek}:
                </Col>
                <Col
                  xs="7"
                  md={isConditionalApproval ? '7' : '4'}
                  className="p-0"
                >
                  {time}
                </Col>
              </Row>
            )
          })}
        </Collapse>
      </React.Fragment>
    )
  } else {
    return <div />
  }
}

YourStoreHours.propTypes = {
  dayOfWeek: PropTypes.string.isRequired,
  hours: PropTypes.arrayOf(
    PropTypes.shape({
      openTime: PropTypes.string,
      closeTime: PropTypes.string,
    })
  ),
  isConditionalApproval: PropTypes.bool,
}

export default YourStoreHours
