import React from 'react'
import PropTypes from 'prop-types'
import ConvertLoanDetailData from './ConvertLoanDetailData'
import LoanConvertContainer from './LoanConvertContainer'
import { LoadingScreen } from 'shared/components'

class LoanConvert extends React.Component {
  render () {
    return (
      <ConvertLoanDetailData customerCode={'007'}>
        {({ loan, loading }) => {
          if (loading) {
            return <LoadingScreen>Loading loan options</LoadingScreen>
          }

          if (loan) {
            return <LoanConvertContainer loanDetails={loan} />
          }
        }}
      </ConvertLoanDetailData>
    )
  }
}

LoanConvert.propTypes = {
  onContinue: PropTypes.func,
}

export default LoanConvert
