import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Alert, FormGroup } from 'reactstrap'
import { PdfViewer } from 'shared/components'
import ConsentCheck from '../../../application/components/Agreements/ConsentCheck'

const ReadAgrees = ({
  agreementsError,
  readAndAgrees,
  currentDisclosures,
  onDecision,
}) => {
  const [pdfUrl, setPdfUrl] = useState(null)
  const [pdfData, setPdfData] = useState(null)
  const [pdfTitle, setPdfTitle] = useState(null)
  const [pdfOnAgree, setPdfOnAgree] = useState(null)
  const [initialized, setInitialized] = useState(false)
  const [docs, setDocs] = useState({})
  const [currentReadAndAgree, setCurrentReadAndAgree] = useState(null)

  useEffect(() => {
    if (!initialized && readAndAgrees) {
      let allAgreed = true
      const newDocs = {}
      readAndAgrees.forEach(doc => {
        const accepted = disclosureInitialValue(currentDisclosures, doc.idTag)
        const field = `readAgree_${doc.idTag}`
        newDocs[field] = accepted
        if (!accepted) {
          allAgreed = false
        }
      })
      setDocs(newDocs)
      setInitialized(true)
      onDecision(allAgreed)
    }
  }, [readAndAgrees, currentDisclosures, onDecision, initialized])

  const disclosureInitialValue = (disclosures, key) => {
    if (disclosures) {
      return (
        disclosures
          .filter(d => d.idTag === key)
          .map(d => d.value)
          .find(d => d) || false
      )
    }
    return false
  }

  const toggleReadAndAgree = document => {
    const field = `readAgree_${document.idTag}`
    const readAndAgree = docs[field]
    setCurrentReadAndAgree(document.idTag)

    if (readAndAgree) {
      decisionReadAndAgree(false, document.idTag)
    } else {
      showReadAndAgree(document)
    }
  }

  const showReadAndAgree = document => {
    setCurrentReadAndAgree(document.idTag)

    setPdfTitle(document.title || 'GENERAL CONSENT')
    setPdfUrl(document.url)
    setPdfData(document.pdfData)
    setPdfOnAgree(true)
  }

  const decisionReadAndAgree = (accepted, idTag = currentReadAndAgree) => {
    const field = `readAgree_${idTag}`
    const allDocs = { ...docs, [field]: accepted }

    setDocs(allDocs)
    setPdfUrl(null)
    setPdfData(null)
    setCurrentReadAndAgree(null)
    onDecision(allDocumentAgreed(allDocs))
  }

  const readAndAgreeChecked = idTag => {
    const field = `readAgree_${idTag}`
    return docs[field] || false
  }

  const allDocumentAgreed = allDocs => {
    let allAgreed = true
    readAndAgrees.forEach(document => {
      const field = `readAgree_${document.idTag}`

      if (allAgreed && !allDocs[field]) {
        allAgreed = false
      }
    })

    return allAgreed
  }
  const closePdfViewer = () => {
    setPdfUrl(null)
    setPdfData(null)
    setPdfTitle(null)
    setPdfOnAgree(null)
  }

  return (
    <React.Fragment>
      <FormGroup>
        {agreementsError && (
          <Alert color="danger" data-test="agreements-error">
            You must indicate you agree to each of the terms below and have read
            and understood the terms of associated documents
          </Alert>
        )}

        {readAndAgrees &&
          readAndAgrees.map(d => (
            <ConsentCheck
              key={d.idTag}
              contextId={d.idTag}
              checked={readAndAgreeChecked(d.idTag)}
              onCheckboxClick={() => toggleReadAndAgree(d)}
              onButtonClick={() => showReadAndAgree(d)}
              dataTest={`read-agree-${d.idTag}`}
            >
              {d.title}
            </ConsentCheck>
          ))}
      </FormGroup>

      <PdfViewer
        isOpen={pdfUrl !== null || pdfData !== null}
        title={pdfTitle}
        url={pdfUrl}
        pdfData={pdfData}
        closeOnly={!pdfOnAgree}
        onAgree={() => decisionReadAndAgree(true)}
        onDisagree={() => decisionReadAndAgree(false)}
        onClose={closePdfViewer}
      />
    </React.Fragment>
  )
}

ReadAgrees.propTypes = {
  agreementsError: PropTypes.bool,
  readAndAgrees: PropTypes.arrayOf(
    PropTypes.shape({
      idTag: PropTypes.string.isRequired,
      title: PropTypes.string,
      url: PropTypes.string,
      pdfData: PropTypes.string,
    })
  ),
  currentDisclosures: PropTypes.arrayOf(
    PropTypes.shape({
      idTag: PropTypes.string.isRequired,
      value: PropTypes.bool,
    })
  ),
  onDecision: PropTypes.func,
}

export default ReadAgrees
