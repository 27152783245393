import React, { useCallback, useEffect, useRef, useState } from 'react'
import { get } from 'lodash'
import cognito from 'shared/cognito'
import {
  APPLICATION_STATES,
  APPROVED,
  DENIED,
  UNSUBMITTED,
} from '../../../constants/values'
import PropTypes from 'prop-types'

const { REACT_APP_WEBSOCKET_ENV } = process.env

const closeSocket = (code, reason, webSocketRef) => {
  if (
    webSocketRef.current &&
    webSocketRef.current.readyState === WebSocket.OPEN
  ) {
    webSocketRef.current.close(code, reason)
  }
}

const WebSocketUtil = ({ applicationId, setAppState }) => {
  const webSocketRef = useRef(WebSocket)
  // checking whether the connection is open. not to be confused with the created websocket. l
  // Lowers the amount of attempted connects
  const [isConnectionOpened, setIsConnectionOpened] = useState(false)
  const serverURL = `${REACT_APP_WEBSOCKET_ENV}?Auth=${cognito.idToken}`

  const registerApplicationId = useCallback((applicationId, webSocketRef) => {
    if (webSocketRef.current) {
      webSocketRef.current.send(
        JSON.stringify({
          action: 'registerApplicationListener',
          applicationId: applicationId,
        })
      )
    }
  }, [])

  useEffect(() => {
    const connectOrReconnect = () => {
      if (
        !webSocketRef.current ||
        webSocketRef.current.CLOSED === 3 ||
        webSocketRef.current.readyState === WebSocket.CLOSED
      ) {
        const webSocket = new WebSocket(serverURL)
        webSocketRef.current = webSocket
      }
    }

    if (!isConnectionOpened) {
      connectOrReconnect()
    }

    if (webSocketRef.current) {
      webSocketRef.current.onopen = () => {
        // ensure it is ready
        if (webSocketRef.current.readyState === 1) {
          setIsConnectionOpened(true)
          registerApplicationId(applicationId, webSocketRef)
        }
      }

      webSocketRef.current.onerror = error => {
        setIsConnectionOpened(false)
        setAppState({ error: error })
      }

      webSocketRef.current.onclose = event => {
        if (event.code !== 1000) {
          connectOrReconnect()
        } else {
          setIsConnectionOpened(false)
        }
      }

      webSocketRef.current.onmessage = msg => {
        const message = get(JSON.parse(msg.data), 'data.message.body')
        if (message) {
          const status = get(message, 'status')
          const isError =
            get(message, 'applicationState') ===
            APPLICATION_STATES.SUBMIT_ERROR ||
            get(message, 'applicationState') ===
            APPLICATION_STATES.CANEBAY_DUPLICATE
          if (status !== UNSUBMITTED || isError) {
            if (status === DENIED || isError || status === APPROVED) {
              closeSocket(
                1000,
                'Disconnectin websocket, result found',
                webSocketRef
              )
            }

            setAppState({
              status,
              boCode: get(message, 'externalSystemInformation.customerCode'),
              isError,
            })
          }
        }

        return false
      }
    }
  })

  return <React.Fragment />
}

WebSocketUtil.propTypes = {
  applicationId: PropTypes.string.isRequired,
  setAppState: PropTypes.func.isRequired
}

export default WebSocketUtil
