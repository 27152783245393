import React, { useState } from 'react'
import {
  Col,
  Popover,
  PopoverBody,
  NavItem,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
  Row
} from 'reactstrap'
import { ReactComponent as OrangeScreenShareIcon } from 'shared/images/screenshare-icon.svg'
import { ReactComponent as BlueScreenShareIcon } from 'shared/images/screenshare-icon-blue.svg'
import ChevronNavLink, { Directions } from './ChevronNavLink'
import PropTypes from 'prop-types'

export const RemoteSessionNavLink = ({
  backAction,
  togglePopUp,
  displayRemoteSession,
  isP360
}) => {
  const [showRemotionSessionDisclosure, setShowRemotionSessionDisclosure] = useState(false)
  const [showRemoteCode, setShowRemoteCode] = useState(false)
  const showDiscloureModal = () => {
    window.cobrowseStop()
    setShowRemotionSessionDisclosure(true)
  }

  const handleDisclosureModalToggle = () => {
    setShowRemotionSessionDisclosure(!showRemotionSessionDisclosure)
    togglePopUp()
  }

  return (
    <NavItem className={`d-flex align-items-center flex-column`}>
      <div className="d-flex flex-row w-100 align-items-baseline justify-content-start">
        {(!isP360 || window.innerWidth < 700) && <ChevronNavLink direction={Directions.LEFT} onClick={backAction} />}
        <p className="ml-2">{(showRemoteCode ? 'You can now share your screen' : 'Need additional help?')}</p>
      </div>
      <div className="d-flex flex-row align-items-start screen-share-wording-container">

        {isP360 ?
          <BlueScreenShareIcon
            className="screen-share-icon"
          />
          :
          <OrangeScreenShareIcon
            className="screen-share-icon"
          />
        }
        <p style={{ display: (showRemoteCode) ? 'none' : 'block' }} className="screen-share-wording">
          While you're on the call start a
          remote screen share session with
          your customer service agent.
        </p>
        <p style={{ display: (showRemoteCode) ? 'block' : 'none' }} className="screen-share-wording">
          <span style={{ display: 'block' }} className="h3" id="remoteCode"></span>
          <span>Please provide the above code to your customer service rep to start the session.</span>
        </p>
      </div>
      <div className="remote-session-btn-container">
        <Button style={{ display: (showRemoteCode) ? 'none' : 'block' }} className={`remote-session-btn ${isP360 && 'p360-remote-session-btn'}`}
          onClick={showDiscloureModal}
        >
          Begin Remote Session
      </Button>
        {showRemotionSessionDisclosure && <ShowRemotionSessionDisclosure backAction={backAction} togglePopUp={handleDisclosureModalToggle} displayRemoteSession={displayRemoteSession} setShowRemotionSessionDisclosure={setShowRemotionSessionDisclosure} setShowRemoteCode={setShowRemoteCode} />}
      </div>
    </NavItem>
  )
}

RemoteSessionNavLink.propTypes = {
  backAction: PropTypes.func,
  togglePopUp: PropTypes.func,
  displayRemoteSession: PropTypes.bool,
  isP360: PropTypes.bool
}

const RemoteSessionPopup = ({ displayRemoteSession, backAction, showCollapse, togglePopUp, isP360 }) => {
  return (
    <Popover
      id={Math.random()}
      className="remote-popover overflow-hidden"
      hideArrow={true}
      placement="bottom"
      isOpen={displayRemoteSession}
      target={showCollapse ? 'toggler' : 'chevron-right'}
    >
      <PopoverBody style={{ minWidth: '350px' }}>
        <RemoteSessionNavLink
          backAction={backAction}
          togglePopUp={togglePopUp}
          displayRemoteSession={displayRemoteSession}
          isP360={isP360}
        />
      </PopoverBody>
    </Popover >
  )
}

RemoteSessionPopup.propTypes = {
  displayRemoteSession: PropTypes.bool,
  backAction: PropTypes.func,
  showCollapse: PropTypes.bool,
  togglePopUp: PropTypes.func,
  isP360: PropTypes.bool
}

export const ShowRemotionSessionDisclosure = ({ togglePopUp, displayRemoteSession, backAction, setShowRemoteCode, setShowRemotionSessionDisclosure }) => {
  return (
    <Modal
      isOpen={displayRemoteSession}
      toggle={togglePopUp}
      size="md"
      backdrop="static"
    >
      <ModalHeader toggle={togglePopUp} className="my-0">Screen Share Disclaimer</ModalHeader>
      <ModalBody>

        By clicking, “I Agree” you consent to allowing a third-party agent to see your information and provide
        general information, so you can complete your next step. Only the screens you share will be visible to
        the agent, and your sensitive personal information within the platform will not be visible to the agent.
        The agent will not be able to enter information for you. You are responsible for the accuracy of any
        information included in the platform. Participating in this service does not affect your credit eligibility or
        likelihood of approval.

      </ModalBody>

      <ModalFooter>
        <Row>

          <Col>
            <Button
              id="remote-session-disclosure-cancel"
              color="outline-primary"
              data-test="buttons.reject"
              onClick={() => {
                togglePopUp()
                backAction()
              }}
              disabled={false}
              className=""
            >
              Cancel
                  </Button>
          </Col>
          <Col>
            <Button
              id="remote-session-disclosure-agree"
              color="primary"
              data-test="buttons.submit"
              onClick={() => {
                window.cobrowseStart()
                setShowRemoteCode(true)
                setShowRemotionSessionDisclosure(false)
              }}
              disabled={false}
            >I Agree</Button>
          </Col>
        </Row>



      </ModalFooter>
    </Modal >

  )
}

ShowRemotionSessionDisclosure.propTypes = {
  togglePopUp: PropTypes.func,
  displayRemoteSession: PropTypes.bool,
  backAction: PropTypes.func,
  setShowRemoteCode: PropTypes.func,
  setShowRemotionSessionDisclosure: PropTypes.func
}

export default RemoteSessionPopup
