import React from 'react'
import { LoadingScreen, BrandingCheck } from 'shared/components'
import { sections } from './sections'
import ConversionOfferData from './ConversionOfferData'
import ConversionUnderwriting from './ConversionUnderwriting'
import LoanConversionContainer from './LoanConversionContainer'
import { LOAN_PATHS } from 'constants/paths'

const convertPath = {
  path: LOAN_PATHS.CONVERT,
}

const refinancePath = {
  path: LOAN_PATHS.REFINANCE,
}

class LoanConversion extends React.Component {
  state = {
    selectedOffer: null,
  }

  handleProgressChange = offer => {
    const path = offer === 'CONVERT' ? convertPath.path : refinancePath.path

    //Trim off any previous routes
    if (sections[0].routes.length > 1) {
      sections[0].routes.splice(1, 1)
    }

    if (path === refinancePath.path) {
      sections[0].routes.splice(1, 0, refinancePath)
    } else {
      sections[0].routes.splice(1, 0, convertPath)
    }

    this.setState({ selectedOffer: offer })
  }

  render () {
    const { selectedOffer } = this.state

    return (
      <React.Fragment>
        <BrandingCheck />
        <ConversionUnderwriting customerCode={'007'}>
          {({ preliminaryUnderwriting }) => (
            <ConversionOfferData customerCode={'007'}>
              {({ offers, loading, }) => {
                if (loading) {
                  return <LoadingScreen>Loading offers</LoadingScreen>
                }

                if (offers) {
                  return (
                    <LoanConversionContainer
                      preliminaryUnderwriting={preliminaryUnderwriting}
                      conversionOffers={offers}
                      customerCode={'007'}
                      onUpdateProgress={this.handleProgressChange}
                      selectedOffer={selectedOffer}
                    />
                  )
                }

                return null
              }}
            </ConversionOfferData>
          )}
        </ConversionUnderwriting>
      </React.Fragment>
    )
  }
}

export default LoanConversion
