import { BRANDS } from 'constants/values'
import React from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import Branding from 'shared/branding'

import PropTypes from 'prop-types'

const CaliforniaNotice = ({
  strong = true,
  brand = 'Check ‘n Go and Allied Cash Advance',
}) => {
  const { showNewCaNotice } = useFlags()
  const notice = 'Notice to California consumers:'
  const getNotice = (url) => <p>
    Notice to California consumers: Information on your privacy rights under the California Consumer Privacy Act can be found by <a href={url} target="_blank" rel="noopener noreferrer">clicking here.</a>
  </p>

  const { key } = Branding.current
  if (showNewCaNotice) {
    switch (key) {
      case BRANDS.XACT_BRAND:
        return (
          getNotice("https://www.xact.com/legal/ca/ccpa-policy")
        )
      case BRANDS.CNG:
        return (
          getNotice("https://www.checkngo.com/legal/california-consumer-privacy-act")
        )
      case BRANDS.ACA:
        return (
          getNotice("https://www.alliedcash.com/legal/california-consumer-privacy-act")
        )
      default:
        return (
          getNotice("https://www.checkngo.com/legal/california-consumer-privacy-act")
        )
    }
  } else {
    return (
      <p>
        {strong ? <strong>{notice}</strong> : notice} In order to process credit
        applications, service accounts, and provide the best online and in-store
        experience, {brand === BRANDS.XACT_BRAND ? 'we' : brand}{' '}
        {brand.indexOf('and ') > 0 || brand === BRANDS.XACT_BRAND
          ? 'collect'
          : 'collects'}{' '}
        various categories of information on consumers during the application
        process. Categories of information may include name, address, signature,
        telephone number, internet protocol address, email address, account name,
        social security number, driver’s license or state identification
        information, bank information, credit information, employment-related
        information, internet and network information, geolocation data, product
        preferences, and inferences drawn from this information.
      </p>
    )
  }
}

CaliforniaNotice.propTypes = {
  strong: PropTypes.bool,
  brand: PropTypes.string
}

export default CaliforniaNotice
