import { connectToPlaid } from 'shared/mutations'
import React from 'react'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'

const InitializePlaidMutation = ({ children, ...rest }) => {
  return (
    <Mutation mutation={connectToPlaid}>
      {(initializePlaidConnection, { loading }) =>
        children({ initializePlaidConnection, loading, ...rest })
      }
    </Mutation>
  )
}

InitializePlaidMutation.propTypes = {
  children: PropTypes.func.isRequired,
}

export default InitializePlaidMutation
