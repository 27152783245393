import cognito from 'shared/cognito'
import _ from 'lodash'
import {
  AUTHENTICATION_QUERY,
  UPDATE_AUTHENTICATION_QUERY,
  CUSTOMER_SYSTEM_SEARCH_QUERY,
  SKIP_RESUME_APPLICATION_QUERY,
} from 'authentication/queries'
import { log } from 'shared/logging'
import apolloClient from 'shared/apolloClient'
import history from 'shared/browserHistory'
import { saveError } from 'shared/sentry'
import {
  CUSTOMER_TYPE_FOR_AUTH,
  INCORRECT_BRAND_LOGIN,
  COGNITO_GROUPS,
  LOG_LEVELS,
} from 'constants/values'
import Branding from 'shared/branding'
import { getCookie, setCookieToExpire } from 'shared/cookie'
import { GLOBAL_PATHS } from 'constants/paths'
import PageView from 'shared/PageView'

const BO_CODE = 'custom:BO_Code'
const RETAIL_BO_CODE = 'custom:retail_BO_Code'
const LOANPRO_CUSTOMER_ID = 'custom:loanProCustomerId'
const QFUND_CUSTOMER_ID = 'custom:qFundProCustomerId'
const EMAIL = 'email'
const CUSTOM_SOURCE = 'custom:source'
const CUSTOM_BRAND = 'custom:brand'
const CUSTOM_IS_PASSWORDLESS = 'custom:isPasswordless'

const { REACT_APP_P360_LOGIN: p360Domain } = process.env
class AuthenticationService {
  static isInitialized = false

  initialize = () => {
    if (!AuthenticationService.isInitialized) {
      AuthenticationService.isInitialized = true

      this.updateClient({
        isAuthenticating: true,
        error: null,
      })

      return this.getAuthAttributes()
        .then(
          ({
            boCode,
            retailBoCode,
            loanProCustomerId,
            qFundCustomerId,
            email,
            adminEmail,
            customerType,
            isReturningCustomer,
            isRetailReturning,
            isCoreCustomer,
            customSource,
            customBrand,
            firstName,
            lastName,
            isEnrollment,
            hasOnlinePendingOrigination,
            hasPendingApplication,
            pendingApplicationBrand,
            stateCode,
            channel,
            isPasswordless
          }) => {
            this.updateClient({
              boCode,
              retailBoCode,
              loanProCustomerId,
              qFundCustomerId,
              email,
              adminEmail,
              customerType,
              isReturningCustomer,
              isRetailReturning,
              isCoreCustomer,
              customSource,
              customBrand,
              isAuthenticating: false,
              firstName,
              lastName,
              isEnrollment,
              hasOnlinePendingOrigination,
              hasPendingApplication,
              pendingApplicationBrand,
              stateCode,
              channel,
              isPasswordless
            })
          }
        )
        .catch(error => {
          if (error.message && error.message.includes('REFRESH_TOKEN')) {
            history.replace(GLOBAL_PATHS.LOGIN, { timeout: true })
          } else {
            saveError(error)
          }
          this.signOut()
        })
    }

    return Promise.resolve(this)
  }

  getCognitoAttributeByName = (attributes, attributeName, defaultValue) => {
    return attributes
      ? attributes
        .filter(attr => attr.getName() === attributeName)
        .map(attr => attr.getValue())[0]
      : defaultValue
  }

  getCustomerType = (onlineReturning, retailReturning) => {
    let customerType = CUSTOMER_TYPE_FOR_AUTH.NEW
    if (onlineReturning && retailReturning) {
      customerType = CUSTOMER_TYPE_FOR_AUTH.OMNI_RETURNING
    } else if (onlineReturning) {
      customerType = CUSTOMER_TYPE_FOR_AUTH.ONLINE_RETURNING
    } else if (retailReturning) {
      customerType = CUSTOMER_TYPE_FOR_AUTH.RETAIL_RETURNING
    }
    return customerType
  }

  getAuthAttributes = async () => {
    const isAuthenticated = !!cognito.currentUser && cognito.isSessionValid

    let boCode = null
    let retailBoCode = null
    let loanProCustomerId = null
    let qFundCustomerId = null
    let email = null
    let adminEmail = null
    let customerType = null
    let stateCode = null
    let channel = null
    let isReturningCustomer = false
    let isRetailReturning = false
    let isCoreCustomer = false
    let customSource = null
    let customBrand = null
    let firstName = null
    let lastName = null
    let isEnrollment = false
    let hasOpenApplication = false
    let hasOnlinePendingOrigination = false
    let hasPendingApplication = false
    let pendingApplicationBrand = null
    let isPasswordless = false

    if (isAuthenticated) {
      const currentClient = await apolloClient.query({
        query: AUTHENTICATION_QUERY,
      })
      stateCode = _.get(currentClient, 'data.authentication.stateCode', '')
      channel = _.get(currentClient, 'data.authentication.channel', '')
      const attributes = await cognito.getAttributes()

      if (
        cognito.userGroups &&
        cognito.userGroups.find(g => g === COGNITO_GROUPS.ENROLLMENT)
      ) {
        isEnrollment = true
      }

      boCode = this.getCognitoAttributeByName(attributes, BO_CODE, '')
      retailBoCode = this.getCognitoAttributeByName(
        attributes,
        RETAIL_BO_CODE,
        ''
      )

      loanProCustomerId = this.getCognitoAttributeByName(
        attributes,
        LOANPRO_CUSTOMER_ID,
        ''
      )
      qFundCustomerId = this.getCognitoAttributeByName(
        attributes,
        QFUND_CUSTOMER_ID,
        ''
      )
      email = this.getCognitoAttributeByName(attributes, EMAIL, '')
      if (email) {
        email = email.toLowerCase() //Need to ensure email is always lower case, otherwise we experience cache issues
      }

      customSource = this.getCognitoAttributeByName(
        attributes,
        CUSTOM_SOURCE,
        ''
      )
      customBrand = this.getCognitoAttributeByName(attributes, CUSTOM_BRAND, '')

      isPasswordless = this.getCognitoAttributeByName(
        attributes,
        CUSTOM_IS_PASSWORDLESS,
        false
      ) === 'true'

      let identities = attributes
        ? attributes
          .filter(attr => attr.getName() === 'identities')
          .map(attr => JSON.parse(attr.getValue()))
        : []

      // Flatten out the array of arrays
      identities = [].concat(...identities)

      const { customerSystemSearch } = cognito.userGroups
        ? await this.customerSearch()
        : {}

      if (customerSystemSearch) {
        isRetailReturning = customerSystemSearch.isReturningRetailCustomer
        isReturningCustomer = customerSystemSearch.isReturningCustomer
        isCoreCustomer = customerSystemSearch.isCoreCustomer
        firstName = _.get(
          customerSystemSearch,
          'customerInformation.personalInformation.firstName',
          null
        )
        lastName = _.get(
          customerSystemSearch,
          'customerInformation.personalInformation.lastName',
          null
        )
        hasOpenApplication = customerSystemSearch.hasOpenApplication || false
        hasOnlinePendingOrigination =
          customerSystemSearch.hasOnlinePendingOrigination || false
        hasPendingApplication =
          customerSystemSearch.hasPendingApplication || false
        pendingApplicationBrand =
          customerSystemSearch.pendingApplicationBrand || null
        stateCode = _.get(
          customerSystemSearch,
          'customerInformation.contactInformation.address.stateCode',
          null
        )
      }

      customerType = this.getCustomerType(
        isReturningCustomer,
        isRetailReturning
      )

      if (identities && identities.length === 1 && identities[0].userId) {
        adminEmail = identities[0].userId
        const authAs = this.readAuthAs(adminEmail)
        const cookieAuthAs = getCookie(document, 'adminAuthAs')

        const authEmail = authAs || cookieAuthAs
        if (authEmail) {
          email = authEmail
          setCookieToExpire('adminAuthAs')
        }
      }
    }

    return {
      boCode,
      retailBoCode,
      loanProCustomerId,
      qFundCustomerId,
      email,
      adminEmail,
      customerType,
      isReturningCustomer,
      isRetailReturning,
      isCoreCustomer,
      customSource,
      customBrand,
      firstName,
      lastName,
      isEnrollment,
      hasOpenApplication,
      hasOnlinePendingOrigination,
      hasPendingApplication,
      pendingApplicationBrand,
      stateCode,
      channel,
      isPasswordless,
    }
  }

  

  shouldSkipResumeApplication = async email => {
    const isAuthenticated = !!cognito.currentUser && cognito.isSessionValid
    if (isAuthenticated) {
      const { data } = await apolloClient.query({
        query: SKIP_RESUME_APPLICATION_QUERY,
        variables: { email },
      })
      return data
    }
  }

  signIn = async credentials => {
    try {
      await apolloClient.resetStore()
      await this.updateClient({
        isAuthenticating: true,
        error: null,
      })

      const {
        availableSources,
        cognitoSource,
        key,
        partnerSiteUrl,
      } = Branding.current

      await cognito.signIn(credentials)
      const attributes = await this.getAuthAttributes()

      await this.updateClient({
        isAuthenticating: false,
        boCode: attributes.boCode || '',
        retailBoCode: attributes.retailBoCode || '',
        loanProCustomerId: attributes.loanProCustomerId || '',
        qFundCustomerId: attributes.qFundCustomerId || '',
        email: credentials.email,
        customerType: attributes.customerType,
        isReturningCustomer: attributes.isReturningCustomer,
        isRetailReturning: attributes.isRetailReturning,
        isCoreCustomer: attributes.isCoreCustomer,
        customSource: attributes.customSource,
        customBrand: attributes.customBrand,
        transient:
          !attributes.isReturningCustomer && !attributes.isRetailReturning
            ? credentials.password
            : null,
        firstName: attributes.firstName,
        lastName: attributes.lastName,
        isEnrollment: attributes.isEnrollment,
        hasOnlinePendingOrigination:
          attributes.hasOnlinePendingOrigination || false,
        hasPendingApplication: attributes.hasPendingApplication || false,
        pendingApplicationBrand: attributes.pendingApplicationBrand,
        stateCode: attributes.stateCode,
        channel: attributes.channel,
        isPasswordless : attributes.isPasswordless
      })

      if (
        availableSources.find(s => s === attributes.customBrand) ||
        (!attributes.customBrand && cognitoSource === 'cng.myaccount')
      ) {
        this.fireVPVForCustomer(attributes.customerType)
        log(
          `Successful sign in for customer ${credentials.email} at ${key}`,
          LOG_LEVELS.INFO
        )
        return ''
      } else {
        log(
          `Customer ${credentials.email} attempted login at ${key}, will be directed to ${partnerSiteUrl} for SSO.`,
          LOG_LEVELS.INFO
        )

        throw new Error(INCORRECT_BRAND_LOGIN)
      }
    } catch (error) {
      const message =
        error.code === 'UserNotFoundException'
          ? 'Incorrect username or password.'
          : error.message || 'SignIn Error'
      this.updateClient({
        isAuthenticating: false,
      })
      return message
    }
  }

  signOut = (clearStore = true, onlyLocalStorage = false) => {
    if (window.localStorage) {
      window.localStorage.clear()
    }

    if (!onlyLocalStorage) {
      cognito.signOut()

      this.updateClient({}).then(() => {
        if (clearStore) {
          apolloClient.resetStore() // Soooo, this promise never resolves... don't then/catch here
        }
      })
    }
  }

  loginRedirect = (redirectToP360 = true, isP360Domain = false) => {
    if (redirectToP360 && !isP360Domain) {
      window.location.href = p360Domain
    } else {
      history.push(GLOBAL_PATHS.LOGIN)
    }
  }

  signUp = async credentials => {
    try {
      await this.updateClient({
        isAuthenticating: true,
        error: null,
      })

      await cognito.signUp(credentials)
    } catch (error) {
      saveError(error)
      this.updateClient({
        error: error.message || 'SignUp Error',
      })
    } finally {
      this.updateClient({
        isAuthenticating: false,
      })
    }
  }

  signUpAndSignIn = async (credentials, isEnrollment = false) => {
    try {
      await apolloClient.resetStore()
      await this.updateClient({
        isAuthenticating: true,
        error: null,
      })

      await cognito.signUp(credentials, isEnrollment)
      await cognito.signIn(credentials)

      const { key } = Branding.current

      log(
        `Successful customer registration for ${credentials.email} at ${key}`,
        LOG_LEVELS.INFO
      )

      this.updateClient({
        isAuthenticating: false,
        email: credentials.email,
      })
    } catch (error) {
      let errMsg = error.message || 'Authentication Error'
      if (error.stage === 'SIGNUP' && error.userExists) {
        /* A non-null error message will trigger redirection
         * to the error page. However, the SIGNUP/userExists
         * error is handled differently by the client, so don't
         * set the error message and let the login process
         * handle it.
         */
        errMsg = null
      } else {
        saveError(error)
      }
      this.updateClient({
        isAuthenticating: false,
        error: errMsg,
      })
      throw error
    }
  }

  persistAuthAs = async email => {
    const { adminEmail } = await this.getAuthAttributes()
    if (adminEmail) {
      const key = `cng.authAs.${adminEmail}`
      email ? localStorage.setItem(key, email) : localStorage.removeItem(key)
      return adminEmail
    }

    return null
  }

  readAuthAs = adminEmail => {
    if (adminEmail) {
      const key = `cng.authAs.${adminEmail}`
      return localStorage.getItem(key)
    }

    return null
  }

  authAs = async email => {
    if (cognito.isAdmin) {
      const adminEmail = await this.persistAuthAs(email)
      return this.updateClient({
        email: email ? email.toLowerCase() : null,
        adminEmail,
      })
    }

    return Promise.reject('Not admin')
  }

  singleSignOn = async (location, isLegacyAuthenticated = false) => {
    try {
      await apolloClient.resetStore()
      AuthenticationService.isInitialized = true
      await this.updateClient({
        isAuthenticating: true,
        error: null,
      })
      await cognito.singleSignOn(location)
      const adminAuthAs = getCookie(document, 'adminAuthAs')
      if (adminAuthAs) {
        await this.authAs(adminAuthAs)
      }

      const {
        boCode,
        retailBoCode,
        loanProCustomerId,
        qFundCustomerId,
        email,
        customerType,
        isReturningCustomer,
        isRetailReturning,
        isCoreCustomer,
        customSource,
        customBrand,
        firstName,
        lastName,
        isEnrollment,
        hasOnlinePendingOrigination,
        stateCode,
        channel,
        hasOpenApplication,
        hasPendingApplication,
        pendingApplicationBrand,
        isPasswordless
      } = await this.getAuthAttributes()

      log(
        `Successful SSO for customer ${email} for ${Branding.current.key}`,
        LOG_LEVELS.INFO
      )

      this.fireVPVForCustomer(customerType)

      return this.updateClient({
        boCode,
        retailBoCode,
        loanProCustomerId,
        qFundCustomerId,
        email,
        customerType,
        isAuthenticating: false,
        isLegacyAuthenticated,
        isReturningCustomer,
        isRetailReturning,
        isCoreCustomer,
        customSource,
        customBrand,
        firstName,
        lastName,
        isEnrollment,
        hasOnlinePendingOrigination,
        stateCode,
        channel,
        hasOpenApplication,
        hasPendingApplication,
        pendingApplicationBrand,
        isPasswordless
      })
    } catch (error) {
      saveError(error)
      this.updateClient({
        isAuthenticating: false,
        isLegacyAuthenticated: false,
        error: error.message || 'SSO Error',
      })
      return error
    }
  }

  fireVPVForCustomer = () => {
    PageView.firePageView(GLOBAL_PATHS.LOGIN_SUCCESSFUL)
  }

  customerSearch = async () => {
    const { data } = await apolloClient.query({
      query: CUSTOMER_SYSTEM_SEARCH_QUERY,
    })
    return data
  }

  clearError = () => {
    this.updateClient({ error: null })
  }

  clearTransient = () => {
    return this.updateClient({ transient: null })
  }

  updateClient = (overrides = {}) => {
    const isAuthenticated = !!cognito.currentUser && cognito.isSessionValid
    const variables = {
      isAuthenticated,
      isAdmin: cognito.isAdmin,
      // adminEmail: cognito.isAdmin ? cognito.userId : '',
    }

    /*
     * An update for an authenticated page can resolve AFTER a sign out,
     * so make sure to clear out email and boCode when the cognito token
     * is not authenticated, otherwise we may update a signOut with out of
     * date cognito attributes
     */
    if (!isAuthenticated) {
      overrides.email = ''
      overrides.adminEmail = ''
      overrides.customerType = ''
      overrides.boCode = ''
      overrides.retailBoCode = ''
      overrides.loanProCustomerId = ''
      overrides.qFundCustomerId = ''
      overrides.transient = null
      overrides.firstName = ''
      overrides.lastName = ''
      overrides.customerType = null
      overrides.isEnrollment = false
      overrides.hasOnlinePendingOrigination = false
      overrides.hasPendingApplication = false
      overrides.pendingApplicationBrand = ''
      overrides.stateCode = null
      overrides.channel = null
      overrides.isPasswordless = false
    }

    return apolloClient.mutate({
      mutation: UPDATE_AUTHENTICATION_QUERY,
      variables: Object.assign({}, variables, overrides),
    })
  }

  refreshTokens = async callback => {
    await cognito.refreshTokens(cognito.refreshToken)
    callback()
  }
}

export default new AuthenticationService()
