import { SEO_CAMPAIGN_IDS, CAMPAIGN_ID } from 'constants/values'
import { parseLocationSearch } from 'shared/utils'
import Branding from 'shared/branding'
import Feature from 'features'
import { getCookie, setCookie } from 'shared/cookie'

const {
  key,
  cookie: { name, domain, qsKey },
} = Branding.current

const campaignCookieName = name
const campaignRequestKey = qsKey
const campaignCookieMaxAge = 60 * 60 * 24 * 21 // 21 days in seconds
const campaignDomain =
  Feature.LOCAL && window.location.hostname.indexOf('localhost') >= 0
    ? 'localhost'
    : domain

const campaignCookieString = campaignId =>
  `${campaignCookieName}=${campaignId}; Max-Age=${campaignCookieMaxAge}; Domain=${campaignDomain}; Path=/`

export const campaignDefault = CAMPAIGN_ID[key].ONLINE

export const readCampaignFromLocation = location => {
  if (location && location.search) {
    const queryObject = parseLocationSearch(location.search.toLowerCase())
    return Object.getOwnPropertyNames(queryObject)
      .filter(name => name === campaignRequestKey)
      .map(name => queryObject[name])
      .map(Number)
      .find(id => id)
  }

  return undefined
}

export const readCampaignFromReferrer = refUrl => {
  if (refUrl) {
    const seoCampaigns = SEO_CAMPAIGN_IDS[key]
    return Object.getOwnPropertyNames(seoCampaigns)
      .filter(name => refUrl.toLowerCase().includes(name.toLowerCase()))
      .map(name => seoCampaigns[name])
      .find(id => id)
  }

  return undefined
}

export const loadCampaignId = (doc = document) =>
  parseInt(getCookie(doc, campaignCookieName), 10)
    ? parseInt(getCookie(doc, campaignCookieName), 10)
    : undefined

export const saveCampaignId = (campaignId, doc = document) => {
  setCookie(doc, campaignCookieString(campaignId))
}
