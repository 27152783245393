import React, { useCallback, useEffect } from 'react'
import get from 'lodash/get'
import pickBy from 'lodash/pickBy'
import { Button } from 'reactstrap'
import { usePlaidLink } from 'react-plaid-link'
import { APPLICATION_PATHS } from 'constants/paths'
import { PLAID_ERROR_CODE, PLAID_EVENT } from 'constants/plaid'
import { BANK_ACCOUNT_TYPE, LOG_LEVELS } from 'constants/values'
import Branding from 'shared/branding'
import { logDetails } from 'shared/logging'
import PageView from 'shared/PageView'
import { useFlags } from 'launchdarkly-react-client-sdk'
import PropTypes from 'prop-types'

const PlaidComponent = ({
  applicationId,
  connect,
  linkToken,
  setComplete,
  setIsProcessing,
  setPlaidData,
  setPlaidError,
  setInstitution,
  isUpdateMode,
  setPublicToken,
  isOAuth,
  oAuthRedirectUri,
  isConsentPage,
  setIsConsentPage,
}) => {
  const { showNewBankingScreen, enablePrivacyNoticeConsentPage } = useFlags()

  const onSuccess = useCallback(
    (token, metadata) => {
      const logFields = {
        applicationId,
        institutionName: get(metadata, 'institution.name'),
        link_session_id: get(metadata, 'link_session_id'),
      }
      setInstitution(get(metadata, 'institution.name'))
      logDetails(
        {
          message: 'PlaidLink onSuccess',
          data: { token, metadata, fields: logFields },
        },
        LOG_LEVELS.INFO
      )
      connect({
        variables: {
          id: applicationId,
          publicToken: token,
          isUpdateMode: isUpdateMode,
        },
      }).then(({ data: { initializePlaidConnection } }) => {
        const accounts = get(initializePlaidConnection, 'checkingAccounts', [])
        const publicToken = get(initializePlaidConnection, 'publicToken')
        const authStatus = get(initializePlaidConnection, 'authStatus')
        const basePlaid = {
          isAuthEnabled: get(initializePlaidConnection, 'isAuthEnabled'),
          checkingAccounts: accounts,
          institution: get(initializePlaidConnection, 'institution'),
        }

        if (authStatus === PLAID_ERROR_CODE.ITEM_LOGIN_REQUIRED) {
          setPublicToken(publicToken)
        }
        const bankData = accounts.map(checkingAccount => {
          return {
            id: applicationId,
            accountType: BANK_ACCOUNT_TYPE.CHECKING,
            accountNumber: get(checkingAccount, 'numbers.account'),
            routingNumber: get(checkingAccount, 'numbers.routing'),
            accountBalance: parseInt(
              get(checkingAccount, 'balances.available') ||
              get(checkingAccount, 'balances.current') ||
              0
            ).toString(),
          }
        })
        setPlaidData({
          ...basePlaid,
          bankData: bankData,
        })

        setIsProcessing(false)
        setComplete(true)
      })
    },
    [
      applicationId,
      connect,
      setComplete,
      setIsProcessing,
      setPlaidData,
      setInstitution,
      setPublicToken,
      isUpdateMode,
    ]
  )

  const onEvent = useCallback(
    (eventName, metadata) => {
      const logFields = {
        applicationId,
        eventName,
        errorCode: get(metadata, 'error_code'),
        institutionName: get(metadata, 'institution_name'),
        link_session_id: get(metadata, 'link_session_id'),
      }
      logDetails(
        {
          message: 'PlaidLink onEvent',
          data: { eventName, metadata, fields: logFields },
        },
        LOG_LEVELS.INFO
      )
      switch (eventName) {
        case PLAID_EVENT.HANDOFF:
          setIsProcessing(true)
          break
        case PLAID_EVENT.OPEN:
          PageView.firePageView(APPLICATION_PATHS.PLAID_BANK_LAUNCHED)
          break
        case PLAID_EVENT.ERROR:
          const setError = processPlaidErrorEvent(metadata)
          setPlaidError(setError)
          break
        default:
          break
      }
    },
    [applicationId, setIsProcessing, setPlaidError]
  )

  const onExit = useCallback(
    (err, metadata) => {
      const logFields = {
        applicationId,
        link_session_id: get(metadata, 'link_session_id'),
      }
      let dataToLog
      if (err) {
        dataToLog = { error: err, metadata, fields: logFields }
      } else {
        dataToLog = { metadata, fields: logFields }
      }
      logDetails(
        {
          message: 'PlaidLink onExit',
          data: dataToLog,
        },
        LOG_LEVELS.INFO
      )
    },
    [applicationId]
  )

  const processPlaidErrorEvent = error => {
    const errorCode = get(error, 'error_code')
    switch (errorCode) {
      case PLAID_ERROR_CODE.NO_ACCOUNTS:
        PageView.firePageView(APPLICATION_PATHS.PLAID_NO_CHECKING)
        break
      case PLAID_ERROR_CODE.INVALID_CREDENTIALS:
        PageView.firePageView(APPLICATION_PATHS.PLAID_INVALID_CREDENTIALS)
        break
      case PLAID_ERROR_CODE.UNDEFINED:
        return true
      default:
        return false
    }
    return false
  }

  const config = pickBy({
    token: linkToken,
    clientName: Branding.current.name,
    env: process.env.REACT_APP_PLAID_ENVIRONMENT,
    product: ['assets'],
    publicKey: process.env.REACT_APP_PLAID_PUBLIC_KEY,
    linkCustomizationName: Branding.current.plaidThemeName,
    accountSubtypes: { depository: ['checking'] },
    onSuccess,
    onEvent,
    onExit,
  })

  if (isOAuth) {
    config.receivedRedirectUri = `${window.location.href}${oAuthRedirectUri}`
  }

  const { open, ready, error } = usePlaidLink(config)

  useEffect(() => {
    // If OAuth redirect, instantly open link when it is ready instead of making user click the button
    if (isOAuth && ready) {
      open()
    }
  }, [ready, open, isOAuth])

  // check for update mode to launch the iframe a bypass of the click below
  if (isUpdateMode) {
    window.scrollTo(0, 0)
    open()
  }

  const openPlaidModal = () => {
    if (!isConsentPage && enablePrivacyNoticeConsentPage) {
      setIsConsentPage()
    } else {
      window.scrollTo(0, 0)
      localStorage.setItem(
        'oauthConfig',
        JSON.stringify({
          token: linkToken,
        })
      )
      open()
    }
  }

  const getButtonText = () => {
    if (!showNewBankingScreen) {
      return 'Connect to Account'
    } else if (showNewBankingScreen && !isConsentPage) {
      return 'Connect Account'
    } else if (
      showNewBankingScreen &&
      isConsentPage &&
      enablePrivacyNoticeConsentPage
    ) {
      return 'I consent - connect account'
    }
  }

  return (
    <Button
      className={
        showNewBankingScreen
          ? 'btn-block mt-2 mobile-stacked-button float-right'
          : 'mt-2 mobile-stacked-button float-right'
      }
      id="bank_information_form--button-connect"
      color="primary"
      data-test="buttons.connect"
      onClick={openPlaidModal}
      disabled={!ready || error}
    >
      {!showNewBankingScreen && <i className="fas fa-lg fa-university mr-3" />}
      {getButtonText()}
    </Button>
  )
}

PlaidComponent.propTypes = {
  applicationId: PropTypes.string,
  connect: PropTypes.func,
  linkToken: PropTypes.string,
  setComplete: PropTypes.func,
  setIsProcessing: PropTypes.func,
  setPlaidData: PropTypes.func,
  setPlaidError: PropTypes.func,
  setInstitution: PropTypes.func,
  isUpdateMode: PropTypes.bool,
  setPublicToken: PropTypes.func,
  isOAuth: PropTypes.bool,
  oAuthRedirectUri: PropTypes.bool,
  isConsentPage: PropTypes.bool,
  setIsConsentPage: PropTypes.func,
}

export default PlaidComponent
