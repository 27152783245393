import React from 'react'
import {
  NavLink
} from 'reactstrap'
import PropTypes from 'prop-types'

export const Directions = {
  LEFT: 'left',
  RIGHT: 'right'
}

const ChevronNavLink = ({
  onClick,
  direction
}) => {
  return (<NavLink
    id={`chevron-${direction}`}
    onClick={onClick}
    className="pointer"
    data-test="chevron-nav-link"
  >
    <i className={`fa fa-chevron-${direction}`}></i>
  </NavLink>)
}

ChevronNavLink.propTypes = {
  onClick: PropTypes.func,
  direction: PropTypes.string
}

export default ChevronNavLink
