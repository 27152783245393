import gql from 'graphql-tag'

export const DisclosuresBySection = gql`
  query getBaseDisclosures(
    $brandName: String!
    $disclosureType: String
    $section: String
    $applicationId: ID
  ) {
    getBaseDisclosures(
      brandName: $brandName
      disclosureType: $disclosureType
      section: $section
      applicationId: $applicationId
    ) {
      idTag
      title
      type
      copy
      url
      pdfData
    }
  }
`

export const StateDisclosuresQuery = gql`
  query StateDisclosuresQuery(
    $stateCode: String!
    $brandName: String!
    $channel: String
    $section: String
    $applicationId: ID
  ) {
    documentsPerState(
      stateCode: $stateCode
      brandName: $brandName
      channel: $channel
      section: $section
      applicationId: $applicationId
    ) {
      id
      disclosures {
        idTag
        title
        type
        copy
        url
        pdfData
      }
      requiredFields {
        copy
        disclosure
        inputs {
          type
          idTag
          label
          value
        }
      }
    }
    getProductAvailability(stateCode: $stateCode, brandName: $brandName) {
      products {
        name
        productCode
        channel
      }
    }
  }
`

export const LearningCenterQuery = gql`
  query LearningCenterQuery {
    getLearningCenterLinks {
      title
      link
      active
    }
  }
`

export const OpenApplicationQuery = gql`
  query OpenApplicationQuery($email: String!, $isGPCEnabled: Boolean) {
    openApplication(email: $email, isGPCEnabled: $isGPCEnabled) {
      id
      status
      storeInformation {
        brandName
      }
      stateCode
      channel
      applicationType
      applicationSource
      originalLoanDetails {
        originalLoanTerm
      }
      externalSystemInformation {
        source
        customerCode
      }
    }
  }
`

export const GenerateBokuUrlQuery = gql`
  query generateBokuEVURL($applicationId: ID!, $phoneNumber: String!) {
    generateBokuEVURL(applicationId: $applicationId, phoneNumber: $phoneNumber)
  }
`

export const GetApplication = gql`
  query GetApplication($id: ID!) {
    application(id: $id) {
      id
      applicationType
      applicationSource
      applicationNumber
      source
      stateCode
      campaignId
      ipAddress
      invalidPrecheck
      status
      channel
      preferredProductCode
      retailOnly
      onlineOnly
      suppressedBrands
      userInformation {
        email
      }
      customerInformation {
        personalInformation {
          firstName
          lastName
          dateOfBirth
          documentType
          documentNumber
          stateIdType
          driverLicenseNumber
          driverLicenseStateCode
          stateIdExpirationDate
        }
        contactInformation {
          address {
            addressLine1
            addressLine2
            cityName
            stateCode
            zipCode
          }
          phones {
            type
            number
            extension
            phonePreference
          }
          emails {
            type
            email
            verified
          }
        }
        additionalInformation {
          monthsAtAddress
          ownResidence
        }
        spousalInformation {
          firstName
          lastName
          email
          phoneNumber
        }
        bankingInformation {
          isReadOnly
          routingNumber
          accountNumber
          accountHoldersName
          accountBalance
          bankName
          accountOpenDate
        }
        incomeInformation {
          type
          employmentInformation {
            name
          }
          employmentHireDate
          incomeWithholding
          jobTitle
          paymentFrequency
          paymentMethod
          amountGross
          amountNet
          nextPaymentDate
          paymentDayOfWeek
          jobShift
        }
      }
      storeInformation {
        storeNumber
        brandName
      }
      loanApplication {
        additionalAttributes {
          optOut
          canShareInfo
          smsOptIn
          otpResponse
          iovationBlackBox
          flagAutoOrigination
          disclosures {
            idTag
            value
          }
        }
      }
      loanApplicationProgress {
        applicationUrl
      }
      initialApplicationDetails {
        stateCode
        product {
          productCode
          channel
        }
      }
      conversionDetails {
        loanNumber
        productCode
        payoffAmount
      }
      originalLoanDetails {
        loanNumber
        productCode
        payoffAmount
        dueDate
        principal
        totalAmountDue
        loanDisplayNumber
        sourceSystem
        originalLoanTerm
      }
      applicationState
      applicationStateDetails {
        submissionFlow
      }
      externalSystemInformation {
        source
        customerCode
      }
      createdAt
      modifiedAt
    }
  }
`
export const GetAnotherOption = gql`
  query GetApplication($id: ID!) {
    application(id: $id) {
      id
      onlineOnly
      retailOnly
      denial {
        onlineEligible
        retailEligible
      }
    }
  }
`

export const CheckPendingApplication = gql`
  query PendingApplication($email: String!) {
    unsubmittedApplication(email: $email) {
      id
      userInformation {
        email
      }
    }
  }
`

export const GetAnotherOptionDetails = gql`
  query getAnotherOptionDetail(
    $applicationId: ID!
    $partnerBrand: String
    $boCode: String
  ) {
    getAnotherOptionDetail(
      applicationId: $applicationId
      partnerBrand: $partnerBrand
      boCode: $boCode
    ) {
      applicationId
      anotherOptionAvailable
    }
  }
`

export const GetAdverseActionDetails = gql`
  query getAdverseActionDetail(
    $applicationId: ID!
    $stateCode: String!
    $brandName: String!
  ) {
    getAdverseActionDetail(
      applicationId: $applicationId
      stateCode: $stateCode
      brandName: $brandName
    ) {
      applicationId
      hasRetailStores
    }
  }
`

export const GetApplicationState = gql`
  query GetApplicationState($id: ID!) {
    applicationState(id: $id) {
      id
      status
      isPendingDocuments
      isPendingEsign
      isEsignDisabled
      eSignDisabledReasons
      eSignDisabledUntilDate
    }
  }
`

export const GetProductAvailabilityQuery = gql`
  query getProductAvailability(
    $stateCode: String!
    $brandName: String!
    $partnerBrand: String
  ) {
    getProductAvailability(
      stateCode: $stateCode
      brandName: $brandName
      partnerBrand: $partnerBrand
    ) {
      stateCode
      retailChoiceEnabled
      products {
        name
        productCode
        channel
        brand
        lenderBrand
        minLoan
        maxLoan
        minTerm
        maxTerm
        aprLow
        aprHigh
        exampleBorrow
        exampleAPR
        exampleTerm
        examplePaymentAmount
        xactExamples {
          minApr
          maxApr
          terms {
            term
            principal
            apr
            installmentPayments
            totalPayments
            returningRefiOnly
          }
        }
      }
      partnerHasOnline
      partnerHasRetail
    }
  }
`

export const GetCustomerProductAvailabilityQuery = gql`
  query getCustomerProductAvailability(
    $applicationId: ID!
    $partnerBrand: String
    $boCode: String
  ) {
    getCustomerProductAvailability(
      applicationId: $applicationId
      partnerBrand: $partnerBrand
      boCode: $boCode
    ) {
      stateCode
      customerEligibleForBank
      customerStoreNumber
      retailChoiceEnabled
      partnerHasRetail
      partnerHasOnline
      nonCheetahRetailAvailable
      products {
        name
        productCode
        channel
        brand
        lenderBrand
        minLoan
        maxLoan
        minTerm
        maxTerm
        aprLow
        aprHigh
        exampleBorrow
        exampleAPR
        exampleTerm
        exampleTotal
        examplePaymentAmount
        xactExamples {
          minApr
          maxApr
          terms {
            term
            principal
            apr
            installmentPayments
            totalPayments
            returningRefiOnly
          }
        }
      }
      preApprovedProducts {
        name
        productCode
        channel
        brand
        lenderBrand
        minLoan
        maxLoan
        minTerm
        maxTerm
        aprLow
        aprHigh
        exampleBorrow
        exampleAPR
        exampleTerm
        examplePaymentAmount
      }
      applicationConversionEligibility {
        hasOpenRetailLoan
        conversionDetails {
          brandName
          isEligible
          loanNumber
          payoffAmount
          productCode
          sourceSystem
        }
      }
    }
  }
`

export const GetStateDataQuery = gql`
  query getDocumentsPerState(
    $stateCode: String!
    $brandName: String!
    $partnerBrand: String
  ) {
    getProductAvailability(
      stateCode: $stateCode
      brandName: $brandName
      partnerBrand: $partnerBrand
    ) {
      stateCode
      retailChoiceEnabled
      products {
        name
        productCode
        channel
        brand
        lenderBrand
        minLoan
        maxLoan
        minTerm
        maxTerm
        aprLow
        aprHigh
        xactExamples {
          minApr
          maxApr
          terms {
            term
            principal
            apr
            installmentPayments
            totalPayments
            returningRefiOnly
          }
        }
      }
      partnerHasOnline
      partnerHasRetail
    }
    documentsPerState(stateCode: $stateCode, brandName: $brandName) {
      id
      abbr
      name
    }
  }
`

export const GetStateIdPatternQuery = gql`
  query getStateIdPatterns($stateCode: String!, $brandName: String!) {
    documentsPerState(stateCode: $stateCode, brandName: $brandName) {
      id
      abbr
      name
      idPattern {
        rule
      }
    }
  }
`

export const GetCurrentDate = gql`
  query getCurrentDate {
    getCurrentDate {
      currentDate
    }
  }
`

export const GetStatesForZipcodeQuery = gql`
  query statesForZipcode($zipcode: String!) {
    statesForZipcode(zipcode: $zipcode) {
      state {
        name
        abbr
      }
    }
  }
`

export const GetLegacySecuredQuerystringQuery = gql`
  query sqsLegacy(
    $customerCode: String!
    $idToken: String
    $accessToken: String
    $destination: String
  ) {
    sqsLegacy(
      customerCode: $customerCode
      idToken: $idToken
      accessToken: $accessToken
      destination: $destination
    ) {
      redirectUrl
    }
  }
`

export const OtpVerificationSearch = gql`
  query otpSearch($otpSearchRequest: OtpSearchRequest!) {
    otpSearch(otpSearchRequest: $otpSearchRequest) {
      id
      phoneNumbers {
        type
        number
      }
    }
  }
`

export const BankVerificationsSearch = gql`
  query BankVerificationsSearch($applicationId: String!) {
    searchBankVerification(applicationId: $applicationId) {
      bankVerifications {
        id
        applicationId
        status
        codes {
          requestCode
          expiresAt
        }
        decisions {
          requestCode
          siteResult
          decisionResult
        }
      }
    }
  }
`

export const IntellicheckVerificationsSearch = gql`
  query IntellicheckVerificationsSearch($applicationId: String!) {
    searchIntellicheckVerification(applicationId: $applicationId) {
      intellicheckVerifications {
        id
        applicationId
        status
        userToken
      }
    }
  }
`

export const ConditionallyApprovedApplicationQuery = gql`
  query conditionallyApprovedApplicationQuery {
    conditionallyApprovedApplication {
      storeInformation {
        storeNumber
      }
      retailStatus
      approvedAt
      daysLeft
      daysElapsed
      preferredProductCode
      channel
    }
  }
`

export const ValidateOriginalLoanDetails = gql`
  mutation validateOriginalLoanDetails($applicationId: ID!) {
    validateOriginalLoanDetails(applicationId: $applicationId) {
      isNonStandardApplication
      isEligible
      hadLoanAmountDeleted
    }
  }
`

export const GetLoanDetails = gql`
  query getLoanDetails($loanId: String!) {
    getInitialLoanDetails(loanId: $loanId) {
      loanNumber
      loanAmount
      financeCharge
      paymentAmount
      paymentDueDate
      convertedLoanNumber
      convertedPayoff
      paymentSchedule {
        installmentNumber
        paymentAmount
        dueDate
      }
    }
  }
`

export const GetLoanDetailsByFlow = gql`
  query getLoanDetailsByFlow(
    $loanId: String!
    $submissionFlow: String!
    $applicationId: ID!
    $isOriginated: Boolean
    $customerCode: String
    $eppAppSchedule: Boolean
  ) {
    getInitialLoanDetails(
      loanId: $loanId
      submissionFlow: $submissionFlow
      isOriginated: $isOriginated
      customerCode: $customerCode
      applicationId: $applicationId
      eppAppSchedule: $eppAppSchedule
    ) {
      loanNumber
      loanAmount
      financeCharge
      paymentAmount
      paymentDueDate
      convertedLoanNumber
      convertedPayoff
      paymentSchedule {
        installmentNumber
        paymentAmount
        dueDate
      }
    }
    application(id: $applicationId) {
      id
      originalLoanDetails {
        dueDate
        financeCharge
        payoffAmount
        loanNumber
      }
    }
  }
`

export const GetSsnQuery = gql`
  query searchSsn($ssn: String!) {
    searchSsn(ssn: $ssn) {
      existsInP360
      existsAsCustomer
      email
      isPasswordless
    }
  }
`

export default {
  DisclosuresBySection,
  StateDisclosuresQuery,
  OpenApplicationQuery,
  GetApplication,
  GetAnotherOption,
  GetStateDataQuery,
  GetStatesForZipcodeQuery,
  GetLegacySecuredQuerystringQuery,
  OtpVerificationSearch,
  BankVerificationsSearch,
  GetProductAvailabilityQuery,
  GetStateIdPatternQuery,
  ValidateOriginalLoanDetails,
  GetLoanDetails,
  GetSsnQuery,
}
