import React from 'react'
import RefinanceDetailData from './RefinanceDetailData'
import RefinanceContainer from './RefinanceContainer'
import { LoadingScreen } from 'shared/components'

class LoanRefinance extends React.Component {
  render () {
    return (
      <RefinanceDetailData customerCode={'007'}>
        {({ refinance, loading, }) => {
          if (loading) {
            return <LoadingScreen>Loading refinance details</LoadingScreen>
          }

          if (refinance) {
            return <RefinanceContainer loanDetail={refinance} />
          }

          return null
        }}
      </RefinanceDetailData>
    )
  }
}

export default LoanRefinance
