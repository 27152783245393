import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import { APPLICATION_PATHS } from 'constants/paths'
import { MapBodyParagraph } from '../ModalUtilities'
import PageView from 'shared/PageView'
import PropTypes from 'prop-types'

const ItemLoginRequiredModal = ({ bankName, setManual, setUpdateMode }) => {
  const [modal, setModal] = useState(true)

  const toggle = () => {
    setModal(!modal)
  }

  const enterManually = () => {
    PageView.firePageView(APPLICATION_PATHS.BANK_MANUAL)
    setManual(true)
  }

  const signInAgain = () => {
    toggle()
    setUpdateMode(true)
  }
  // to maintain a similar output as to the one from default modal
  const body = `While you successfully logged into ${bankName}, due to the security features on your account, your bank requires that you sign in a second time in order for us to obtain your account and routing numbers to pre-fill your application.
        You can choose to sign in again or you can choose to continue your application by manually entering your account information.`

  return (
    <Modal isOpen={modal}>
      <ModalHeader data-test="plaid-item-login-modal-header">
        Institution Requirement
      </ModalHeader>
      <ModalBody>
        <MapBodyParagraph body={body} />
      </ModalBody>
      <ModalFooter className="d-flex flex-column">
        <Button
          id="plaid-item-login-modal--button-continue"
          color="primary"
          onClick={signInAgain}
          data-test="buttons.authorize.submit"
          className="w-100"
        >
          SIGN IN AGAIN
        </Button>

        <Button
          id="plaid-item-login-modal--button-cancel"
          color="primary"
          outline
          className="w-100 mr-0 ml-1 mt-2"
          onClick={enterManually}
          data-test="buttons.authorize.close"
        >
          MANUALLY ENTER ACCOUNT INFO
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ItemLoginRequiredModal.propTypes = {
  bankName: PropTypes.string,
  setManual: PropTypes.func,
  setUpdateMode: PropTypes.func
}

export default ItemLoginRequiredModal
