import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'reactstrap'
import { LoadingScreen, YourStoreDetails } from 'shared/components'
import GoogleMaps from './GoogleMaps'
import StoreAddress from './StoreAddress'
import { productMap, adjustProducts } from 'shared/productMapping'

const StoreLocationReview = ({
  selectedStore,
  loadingSearchResults,
  googleMapsUri,
  googleDirectionsUri,
  selectedProduct,
  isMyAccount,
}) => {
  if (!selectedStore || loadingSearchResults) {
    return <LoadingScreen inline>Loading your store details</LoadingScreen>
  }

  const adjustedProduct = adjustProducts([selectedProduct])[0]
  const preferredProductInfo = productMap[adjustedProduct.productCode]

  const {
    applicationColLarge,
    googleMapsHeight,
    infoDivClass,
    infoColClass,
  } = getStylesForMyAccount(isMyAccount)

  return (
    <React.Fragment>
      <Row className="mt-4" noGutters>
        {isMyAccount ? (
          <Col xs={12} md={6} className="m-0 mb-3 pr-4">
            <div className="h-100">
              <YourStoreDetails
                mapMinHeight={300}
                selectedStore={selectedStore}
                googleDirectionsUri={googleDirectionsUri}
                googleMapsUri={googleMapsUri}
                loadingSearchResult={loadingSearchResults}
                isConditionalApproval={true}
              />
            </div>
          </Col>
        ) : (
          <Col
            xs={12}
            md={6}
            lg={applicationColLarge}
            className="mb-3 pr-md-3 border border-dark"
          >
            <div className="h-100">
              <h4 className="px-4 pt-2">Your Store</h4>
              <div className="px-4 pb-3">
                <StoreAddress {...selectedStore} showDetails />
              </div>
            </div>
          </Col>
        )}

        <Col
          id="infoCol"
          xs={12}
          md={6}
          lg={applicationColLarge}
          className={`mb-3  ${infoColClass}`}
        >
          <div id="infoDiv" className={`pr-3 h-100 ${infoDivClass}`}>
            <Row>
              <Col>
                {isMyAccount ? (
                  <div className="pt-3 pl-2 loan-detail current">
                    What to bring?
                  </div>
                ) : (
                  <h2 className="pt-3 pl-3">What to bring?</h2>
                )}
              </Col>
            </Row>
            {preferredProductInfo.beSureToBring.map(document => {
              return (
                <Row key={document.id}>
                  {!isMyAccount && (
                    <Col xs={{ offset: 1, size: 1 }} className="pl-2 pr-0">
                      <i className="fas fa-check fa-lg text-secondary" />
                    </Col>
                  )}
                  <Col id="descriptionCol">
                    <h5 className="pl-2 pr-0">
                      <strong>{document.description}</strong>
                    </h5>
                  </Col>
                </Row>
              )
            })}
          </div>
        </Col>

        {!isMyAccount && (
          <Col
            xs={12}
            lg={applicationColLarge}
            className="mb-lg-3 border border-dark"
          >
            <GoogleMaps
              selectedStore={selectedStore}
              googleMapsUri={googleMapsUri}
              minHeight={googleMapsHeight}
            />
          </Col>
        )}
      </Row>
      {!isMyAccount && (
        <Row>
          <Col className="text-center pt-4">
            <Button
              id="store_location_review--button-get_directions"
              color="primary"
              onClick={() => {
                window.open(googleDirectionsUri)
              }}
            >
              Get Directions
            </Button>
          </Col>
        </Row>
      )}
    </React.Fragment>
  )
}

const getStylesForMyAccount = isMyAccount => {
  const calculatedStyles = isMyAccount
    ? {
      googleMapsHeight: 275,
      infoColClass: 'pl-2 pr-md-3 border border-dark',
      infoDivClass: '',
    }
    : {
      googleMapsHeight: 460,
      infoColClass: 'pl-md-3',
      infoDivClass: 'bg-primary text-light',
    }
  const applicationColLarge = !isMyAccount && 4

  return {
    applicationColLarge,
    ...calculatedStyles,
  }
}

StoreLocationReview.propTypes = {
  loadingSearchResults: PropTypes.bool.isRequired,
  selectedStore: PropTypes.shape({
    storeNumber: PropTypes.number,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    postalCode: PropTypes.string,
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    hours: PropTypes.arrayOf(
      PropTypes.shape({
        openTime: PropTypes.string,
        closeTime: PropTypes.string,
      })
    ),
  }),
  isMyAccount: PropTypes.bool,
  googleMapsUri: PropTypes.string,
  googleDirectionsUri: PropTypes.string,
  selectedProduct: PropTypes.shape({
    productCode: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
  }),
}

export default StoreLocationReview
